/**********************************************************************************************/
/***** HERO ***********************************************************************************/
/**********************************************************************************************/
#hero {
    height:750px;
    z-index:15;
    
    .page-template-page-results &,
    .page-template-page-insights &,
    .blog &,
    .category &,
    .tax-post-type & {
        height:284px;
        
        .overlay_grad {
            display:none;
        }
        
        .crumb {
            color:$black;
            pointer-events:none;
            
            &:before {
                display:none;
            }
            
            &:hover {
                color:$black;
            }
        }
        
        h1 {
            margin:0;
            padding:0;
            color:$black;
        }
        
        .inner_content {
            
        }
    }
    
    .search-results & {
        height:284px;
        
        .overlay_grad {
            display:none;
        }
        
        .content_section_inner {
            background-color:$blue;
        }
        
        h1 {
            margin:0;
            padding:0;
            color:$white;
        }
    }
    
    .single-service & {
        height:512px;
    }
    
    #collage {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        display:flex;
        z-index:-2;
    
        .flex_col_wrap {
            display:inline-block;
            vertical-align:top;
            height:100%;
            width:25%;
            
            &.one {
                
                .flexi_tall {
                    flex:0 0 100%;
                }
            }
        
            &.two {
                width:calc(25% + 20px);
            }
        
            &.three {
                width:calc(33.33% + 20px);
            }
            
            &.four {
                width:calc(16.67% + 20px);
                
                .flexi_short {
                    flex:0 0 50%;
                }
            }
        }
    
        .flex_col {
            display:flex;
            flex-direction:column;
            flex-wrap:nowrap;
            justify-content:flex-start;
            align-items:flex-start;
            height:100%;
        }
    
        .flexi_tall,
        .flexi_short {
            display:block;
            flex:0 1 65%;
            align-self:stretch;
            
            .inner {
                height:100%;
                overflow:hidden;
                background-color:$blue;
                background-repeat:no-repeat;
                background-position:center center;
                background-size:cover;
                box-sizing:border-box;
                transition:all 0.3s;
            }
        }
    
        .flexi_short {
            flex:0 1 35%;
        }
    }
    
    .overlay_grad {
        position:absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        background: -moz-linear-gradient(left,  rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 36%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(left,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 36%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to right,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 36%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=1 );
        z-index:-1;
    }
    
    .content_section_inner {
        position:relative;
        height:100%;
        background-color:transparent;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
        z-index:10;
    }
    
    .hero_inner {
        position:relative;
        display:flex;
        justify-content:flex-start;
        align-items:center;
        height:100%;
    }
    
    .inner_content {
        flex:0 0 auto;
        //width:100%;
        text-align:left;
    }
    
    .crumb {
        margin:0 0 30px;
        color: $white;
        
        &:before {
            display:block;
        }
        
        .single-service & {
            position:absolute;
            top:50px;
            margin:0 0 2em;
            
            &:before {
                background:transparent url(../../images/link-arrow-white.svg) no-repeat center left;
                background-size:cover;
            }
        }
        
        /*&:before {
            background:transparent url(../../images/link-arrow-blue.svg) no-repeat center left;
        }*/
        
        &:hover {
            color: $white;
        }
    }
    
    h1,
    p.heading {
        @extend h1;
        padding-right:25%;
        color: $white;
        
        .single-service & {
            margin:0;
            padding:0;
        }
    }
    
    .subheading {
        padding-right:25%;
        color:$white;
    }
    
    .btn {
        margin:0;
        color:$white;
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    #hero {
        height:calc(100vh - #{$header_h_mobile});
        
        .single-service & {
            height:calc(100vh - #{$header_h_mobile});
        }
    
        h1,
        p.heading {
            padding-right:0;
        }
    
        .subheading {
            padding-right:0;
        }
        
        .inner_content {
            flex:0 0 100%;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #hero {
        margin-bottom:20px;
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    #hero h1 {
        padding-right:0;
    }
    
}