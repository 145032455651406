/**********************************************************************************************/
/***** PRIMARY NAV ****************************************************************************/
/**********************************************************************************************/
#primary-navigation {
    flex:1 1 auto;
    position:relative;
    margin:0;
    padding:0;
    box-sizing:border-box;
    transition:opacity 0.5s;
    
    .nav-menu {
        position:relative;
        margin:0 80px 0 0;
        padding:0;
        list-style:none;
        text-align:right;
        
        > li {
            position:relative;
            display:inline-block;
            margin:0 0 0 -4px;
            transition: all 0.3s ease 0s;
            vertical-align:top;
            text-align:left;
            
            &:first-of-type {
                
            }
            
            &:last-of-type {
                a {
                    padding-right:0;
                }
                
                &:hover > a,
                &:focus > a,
                &.current-menu-item > a,
                &.current-menu-ancestor > a,
                &.current-post-ancestor > a,
                &.current-page-ancestor > a {
                    
                    &:after {
                        width:calc(100% - 20px);
                    }
                }
            }
            
            a {
                display:block;
                position:relative;
                padding:20px 20px;
                line-height:1.22em;
                font-size:18px;
                font-weight: $medium;
                color: $black;
                text-transform:none;
                text-align:center;
                letter-spacing:0.01em;
                transition:all 0.3s;  
                text-decoration:none;
                border-radius: 0;
                
                &:after {
                    position:absolute;
                    left:20px;
                    bottom:12px;
                    width:0;
                    height:5px;
                    content:'';
                    background:$blue;
                    transition:width 0.2s;
                }
            }
            
            &:hover > a,
            &:focus > a,
            &.current-menu-item > a,
            &.current-menu-ancestor > a,
            &.current-post-ancestor > a,
            &.current-page-ancestor > a {
                color:$black;
                
                &:after {
                    width:calc(100% - 40px);
                }
            }
            
            
            &:hover .sub-menu,
            &:focus .sub-menu {
                left:0;
                margin-top:0;
            }
        
            &.flip:hover .sub-menu,
            &.flip:focus .sub-menu {
                left:auto;
                right:0;
            }
        }
        
        ul {
            position: absolute;
            top:100%;
            left: -999em;
            //left:0;
            width:340px;
            min-width:100%;
            margin:0;
            background:$white;
            padding:0 0 20px;
            z-index:1000;
            box-sizing:border-box;            
            
            li {
                position:relative;
                display:block;
                margin:0;
                background:none;
                overflow:hidden;
                
                a {
                    display:block;
                    width:100%;
                    box-sizing:border-box;
                    vertical-align:middle;
                    line-height:1.3em;
                    font-family:$inter;
                    font-weight:$normal;
                    height:auto;
                    padding:12px 24px;
                    color:$black;
                    background:$white !important;
                    transition:all 0.3s;
                    text-align:left;
                    border-radius:0;
                    text-decoration:none;
                    
                    .home & {
                        padding:12px 24px;
                    }
                    
                    .scrolling & {
                        
                    }
                    
                    &:hover,
                    &.active,
                    .nav-menu li:hover &,
                    .nav-menu li:focus & {
                        color: $blue;
                        background:$white !important;
                    }
                    
                }
                
                &.current-menu-item a,
                &.current-menu-ancestor a,
                &.current-post-ancestor a,
                &.current-page-ancestor a {
                    color: $blue;
                    background:$white !important;
                                                        
                    &:hover,
                    &.active,
                    .nav-menu li:hover &,
                    .nav-menu li:focus & {
                        color: $blue;
                        background:$white !important;
                    }
                }
                
                &:first-of-type {
                    
                    a {
                        //border:none;
                    }
                }
            }
        }
    }
}

/* MOBILE NAV */
#primary-navigation-mobile {
    position:relative;
    width:100%;
    padding:0;
    
    .nav-menu {
        list-style:none;
        margin:0;
        padding:0;
                
        > .menu-item-has-children {
            
            > .toggle {
                display:none;
                border:none;
                background:none;
            }
        }
        
        > li {
            margin:0 0 10px;
            border:none;
            
            &:first-child {
                
            }
            
            > a {
                position:relative;
                display:inline-block;
                vertical-align:top;
                width:auto;
                font-size:22px;
                line-height:28px;
                font-family: $inter;
                font-weight: $semi;
                border:none;
                padding:14px 0;
                color: $white;
                text-decoration:none;
                text-transform:uppercase;
                transition:all 0.3s;
                
                &:after {
                    position:absolute;
                    left:0;
                    bottom:14px;
                    width:0;
                    height:2px;
                    content:'';
                    background:$white;
                    transition:width 0.2s;
                }
            }
            
            &.current-menu-item > a,
            &.current-menu-ancestor > a,
            &.current-post-ancestor > a,
            &.current-page-ancestor > a {
                color:$white;
                
                &:after {
                    width:100%;
                }
            }
            
        }
        
        ul {
            display:none;
            list-style:none;
            width:100%;
            margin:0;
            padding:6px 30px 6px 0;
            background:none;
            border-top:none;
            
            li {
                
                
                a {
                    display:block;
                    font-weight:$normal;
                    font-size:0.833em;
                    color: $black;
                    font-weight:$normal;
                    text-transform:none;
                    text-decoration:none;
                    margin:0;
                    padding:5px 0;
                    transition: all 0.3s ease 0s;
                    
                    &:hover {
                        color: $blue;
                    }
                }
                
                /*&.current-menu-item a,
                &.current-menu-ancestor a,
                &.current-post-ancestor a,
                &.current_page_parent a,
                &.current_page_ancestor a,
                &.current-page-ancestor a {
                    
                    
                }*/
                
                > .toggle {
                    display:none;
                }
            }
        }
        
        .current-menu-item,
        .current-menu-ancestor,
        .current-post-ancestor,
        .current_page_parent,
        .current_page_ancestor,
        .current-page-ancestor {
            
            .sub-menu {
                display:none;
            }
        }
        
        .current-menu-item > a {
            
        }
    }
}

.services_nav {
    margin:0 0 1em;
    padding:0;
    list-style:none;
    
    .single-casestudy & {
        margin-left:50px;
    }
    
    > li {
        position:relative;
        display:block;
        margin:0 0 15px;
        
        a {
            display:block;
            position:relative;
            padding:5px 0 5px 74px;
            line-height:40px;
            font-size:1em;
            font-weight: $normal;
            color: $black;
            transition:all 0.3s;  
            text-decoration:none;
            border-radius: 0;
            
            .icon {
                position:absolute;
                left:0;
                top:50%;
                display:flex;
                transform:translateY(-50%);
            }
            
            &:hover {
                color:$blue;
            }
        }
    }
}

@media only screen and (max-width: #{$bp_mid}) { //tablet portrait is the goal here
    
    #primary-navigation {
        display:none;
    }
    #primary-navigation-mobile {
        display:block;
    }
    
}

@media only screen and (max-width: #{$bp_small}) {
    
    #menu-services-side-nav {
        padding:0;
        column-count:2;
        column-gap:40px;
        break-inside:avoid;
    
        .single-service & {
            margin:0 0 40px;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    
    #primary-navigation {
        .nav-menu {
            margin:0 60px 0 0;
        
            > li {
            
                &:last-of-type {
                    a {
                        padding-right:0;
                    }
                
                    &:hover > a,
                    &:focus > a,
                    &.current-menu-item > a,
                    &.current-menu-ancestor > a,
                    &.current-post-ancestor > a,
                    &.current-page-ancestor > a {
                    
                        &:after {
                            width:calc(100% - 12px);
                        }
                    }
                }
            
                a {
                    padding:20px 12px;
                    font-size:15px;
                
                    &:after {
                        left:15px;
                    }
                }
            
                &:hover > a,
                &:focus > a,
                &.current-menu-item > a,
                &.current-menu-ancestor > a,
                &.current-post-ancestor > a,
                &.current-page-ancestor > a {
                                
                    &:after {
                        width:calc(100% - 24px);
                    }
                }
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}