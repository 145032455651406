/**********************************************************************************************/
/***** SEARCH *********************************************************************************/
/**********************************************************************************************/
#search_toggle {
    position:absolute;
    right:0;
    top:50%;
    width:20px;
    height:20px;
    //background:transparent url('../../images/search-mag-glass.svg') no-repeat center center;
    //background-size:34px auto;
    transform:translateY(-50%);
    cursor:pointer;
    z-index:1020;
    
    svg {
        position:absolute;
        left:0;
        top:0;
        
        #search-mag-glass {
            fill:$blue;
            transition:fill 0.3s;
        }
        
        #search-close {
            display:none;
            fill:$white;
        }
    }
    
    &:hover {
        
    }
    
    &.active {
        svg {
            #search-mag-glass {
                display:none;
            }
        
            #search-close {
                display:block;
            }
        }
    }
    
    .mobile_nav_open & {
        //background-image: url('../../images/search-mag-glass-white.svg');
    }
}

#search_container {
    position:absolute;
    right:-24px;
    top:-78%;
    height:78%;
    width:500px;
    transition: all 0.5s ease-in-out;
    background:$blue;
    z-index:1020;
    
    &.active {
        top:0px;
    }
    
    .search-submit {
        display:none;
    }
    
    
}

#search_container_mobile {
    display:none !important;
}

#searchform {
    position:absolute;
    left:58px;
    right:58px;
    bottom:27%;
    
    .scrolling & {
        bottom:20%;
    }
    
    input[type='text'] {
        width:100%;
        padding:0;
        border:none;
        border-bottom:1px solid $white;
        line-height:30px;
        font-family: $inter;
        font-weight:$semi;
        border-radius:0;
        //border-left:1px solid rgba(255,255,255,0.5);
        -webkit-appearance:none;
        font-size: 0.75em; /* 15px */
        line-height:30px;
        letter-spacing:0.01em;
        color: $white;
        text-transform:uppercase;
        background:none;
        transition:all 0.3s;
    
        &:focus,
        &:valid {
            outline:none;
        }
        
        &:focus {
            border-left-color:rgba(255,255,255,0);
        }
    }
    
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(255,255,255,1);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(255,255,255,1);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(255,255,255,1);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(255,255,255,1);
    }
}

#search-results.search-results {
    position:absolute;
    right:-58px;
    top:100%;
    width:calc(100% + 116px);
    padding-top:20px;
    background:$blue;
    
    &.loading {
        display:block;
        min-height:100px;
        background:$blue url(../../images/loading-white.svg) no-repeat center center;
    }
    
    .search-results-no-results {
        padding:40px 58px 40px 58px;
        color: $white;
        font-size:0.9em;
        font-weight:$bold;
    }
    
    .search-result-item {
        display:block;
        padding:10px 58px 10px 58px;
        color: $white;
        font-size:0.8em;
        font-weight:$normal;
        line-height:1.2em;
        text-decoration:none;
        
        &.btn {
            display:inline-block;
            position:relative;
            margin:20px 0 20px 58px;
            padding:10px 30px;
            font-size:16px;
            text-align:left;
            cursor:pointer;
	        
	        &:hover {
	            color: $blue !important;
	        }
        }
        
        &:first-child {
            margin-top:0;
        }
        
        &:hover {
            background: $black;
            color: $white;
        }
        
        .strapline {
            display:block;
            position:relative;
            margin:0;
            padding:0 24px;
            height:24px;
            line-height:24px;
            text-transform:uppercase;
            font-weight:$bold;
            font-size:0.75em;
            color:$white;
            background:transparent;
            background-repeat:no-repeat;
            background-image:url(../../images/insight-icon-article-white.svg);
            background-position:left center;
            background-size:auto 16px;
            
            &.article,
            &.news,
            &.page {
                background-image:url(../../images/insight-icon-article-white.svg);
            }
        
            &.whitepaper {
                background-image:url(../../images/insight-icon-whitepaper-white.svg);
            }
        
            &.podcast {
                padding-left:24px;
                background-image:url(../../images/insight-icon-podcast-white.svg);
            }
        
            &.video,
            &.webinar {
                padding-left:28px;
                background-image:url(../../images/insight-icon-video-white.svg);
            }
        }
    }
}

#search_results_cont {
    
    
    .search_results {
        
        
        
        .item {
            margin:0 0 40px;
            border-bottom:1px solid rgba(34,34,34,0.2);
            padding-bottom:40px;
            
            &:last-of-type {
                margin:0;
            }
        }
        
        h2 {
            margin:0 0 1em;
                
            strong, b {
                font-weight:$bold;
            }
            
            a {
                text-decoration:none;
                color: $black;
                
                &:hover {
                    color:$blue;
                    opacity:1;
                }
            }
        }
        
        .excerpt {
            margin:1.3em 0 0;
            font-size:0.75em;
            
            a {
                display:inline;
                font-weight:$bold;
            }
        }
        
        .byline {
            margin:0;
            font-size:0.75em;
            
            a {
                font-weight:$bold;
                text-decoration:underline;
                text-decoration-thickness:0.08em;
                text-underline-offset:0.2em;
                color: $black;
                
                &:hover {
                    color: $blue;
                    opacity:1;
                }
            }
        }
        
        .meta_topics {
            margin:0 0 0.5em;
            font-size:0.9em;
            
            a {
                font-weight:$bold;
            }
        }
    }
}


@media only screen and (max-width: 990px) {
    
    
    
}

@media only screen and (max-width: #{$bp_mid}) {
    
    #search_toggle,
    #search_container {
        display:none !important;
    }
    
    #search_container_mobile {
        display:block !important;
        margin-top:40px;
        
        #searchform_mobile {
            position:relative;
            right:auto;
            left:0;
            bottom:auto;
            
            input[type='text'] {
                width:calc(100% - 30px);
                padding:0;
                border:none;
                border-bottom:1px solid $white;
                line-height:28px;
                font-family: $inter;
                font-weight:$semi;
                border-radius:0;
                //border-left:1px solid rgba(255,255,255,0.5);
                -webkit-appearance:none;
                font-size: 22px;
                letter-spacing:0.01em;
                color: $white;
                text-transform:uppercase;
                background:none;
                transition:all 0.3s;
    
                &:focus,
                &:valid {
                    outline:none;
                }
        
                &:focus {
                    border-left-color:rgba(255,255,255,0);
                }
            }
    
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: rgba(255,255,255,1);
            }
            ::-moz-placeholder { /* Firefox 19+ */
              color: rgba(255,255,255,1);
            }
            :-ms-input-placeholder { /* IE 10+ */
              color: rgba(255,255,255,1);
            }
            :-moz-placeholder { /* Firefox 18- */
              color: rgba(255,255,255,1);
            }
            
            .search-submit {
                display:block;
                position:absolute;
                right:0;
                top:50%;
                width:28px;
                height:28px;
                padding:0;
                border:none;
                overflow:hidden;
                text-indent:-9999px;
                background:transparent url('../../images/search-mag-glass-white.svg') no-repeat center center;
                background-size:28px auto;
                transform:translateY(-50%);
                cursor:pointer;
                z-index:1020;            
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}