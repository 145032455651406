/**********************************************************************************************/
/***** SITEWIDE BUTTONS ***********************************************************************/
/**********************************************************************************************/
a.btn,
.btn a,
a span.btn,
button.btn,
.wp-block-button__link,
span.rm_link {
    display:inline-block;
    position:relative;
    margin:0 0 1em 0;
    padding:10px 30px;
    width:auto;
    background:none !important;
    font-family: $inter;
    font-size: 0.75em;
    font-weight: $bold;
    font-style:normal;
    line-height:1.2em !important;
    color: $blue !important;
    border:2px solid $blue;
    text-decoration:none;
    box-sizing:border-box;
    text-align:center;
    -webkit-appearance:none;
    transition:all 0.3s;
    cursor:pointer;
    opacity:1;
    
    #secondary & {
        padding:10px 24px;
        font-size:0.7em;
    }
    
    &.white {
        color: $white !important;
        border-color:$white !important;
        
        &:hover {
            color: $black !important;
            background:$white !important;
        }
    }
    
    &:hover {
        color: $white !important;
        background:$blue !important;
        opacity:1 !important;
    }
    
    &.arrow {
        position:relative;
        padding:0 30px 0 0;
        letter-spacing:0.2em;
        font-size:14px !important;
        color:$black !important;
        font-weight:$normal;
        text-transform:uppercase;
        line-height:1.2em;
        text-decoration:none;
        border:none !important;
        background:none !important;
        
        &:after {
            position:absolute;
            right:0;
            top:50%;
            transform:translateY(-50%);
            width:18px;
            height:12px;
            background:transparent url(../../images/link-arrow-blue.svg) no-repeat center right;
            background-size:cover;
            content:'';
            transition:right 0.3s;
        }
    
        &.white {
            color: $white !important;
        
            &:after {
                background-image: url(../../images/link-arrow-white.svg);
            }
        
            &:hover {
                color: $white !important;
            }
        }
    
        &.gray {
            color: $gray !important;
        
            &:after {
                background-image: url(../../images/link-arrow-gray.svg);
            }
        
            &:hover {
                color: $gray !important;
            }
        }
    
        &:hover {
            color:$black !important;
            border:none !important;
            background:none !important;
        
            &:after {
                right:-10px;
            }
        }
    }
}

input[type='image'],
input[type='submit'],
input[type='button'],
button.gpnf-add-entry {
    padding:10px 30px;
    font-size:0.75em;
    text-decoration:none;
    border:2px solid $blue;
    color:$blue;
    background-color:transparent;
    font-weight:$bold;
    transition:all 0.3s;

    &.white {
        color: $white !important;
        border-color:$white !important;
    
        &:hover {
            color: $blue !important;
            background-color:$white !important;
            border-color:$white !important;
        }
    }

    &:hover {
        color: $white !important;
        border-color:$blue !important;
        background-color:$blue !important;
    }

    &:focus {
        outline:none;
    }
    
    #secondary & {
        padding:10px 24px;
        font-size:0.7em;
    }
}

a.btn.disabled,
.btn a.disabled,
input[type='image']:disabled,
input[type='submit']:disabled,
input[type='button']:disabled,
button.btn:disabled,
button.gpnf-add-entry:disabled,
.wp-block-button__link.disabled {
    cursor:default;
    color: $white !important;
    background: $light_gray !important;
    border-color: $light_gray !important;
    
    &:hover {
        cursor:default;
        color: $white !important;
        background: $light_gray !important;
        border-color: $light_gray !important;
    }
}

.read_more {
    
}


// GUTENBERG BUTTON STYLES
.wp-block-buttons {
    width:100%;
    max-width:1420px;
    margin-right:auto;
    margin-left:auto;

    .wp-block-button {
        margin-bottom:0;
        margin-right:20px;
        
        &:last-child {
            margin-right:0;
        }
    }
}

.wp-block-button__link {
	border-radius: 0;
}


@media screen and (max-width: #{$bp_small}) {

    a.btn,
    .btn a,
    a span.btn,
    input[type='image'],
    input[type='submit'],
    input[type='button'],
    button.btn,
    button.gpnf-add-entry,
    .wp-block-button__link {
        font-size:1em; //body @ 17px
    }
    
    
    input[type='image'],
    input[type='submit'],
    input[type='button'],
    button.gpnf-add-entry {
        font-size:1em;
        text-decoration:none;
        border:1px solid $blue;
        color:$blue;
        background-color:transparent;
        font-weight:$bold;
        transition:all 0.3s;

        &.white {
            color: $white !important;
            border-color:$white !important;
            background-color:$blue !important;
    
            &:hover {
                color: $blue !important;
                background-color:$white !important;
                border-color:$blue !important;
            }
        }

        &:hover {
            color: $white !important;
            border-color:$blue !important;
            background-color:$blue !important;
        }

        &:focus {
            outline:none;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}