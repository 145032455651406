/**********************************************************************************************/
/***** RESET **********************************************************************************/
/**********************************************************************************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/**********************************************************************************************/
/***** STRUCTURAL *****************************************************************************/
/**********************************************************************************************/
html {
    height:100%;
}
body {
    height:auto !important;
    min-height:100%;
    //padding-top:1px;
    box-sizing:border-box;
    font-family: $inter;
    font-weight: $normal;
    font-size: 24px;
	line-height: 1.54em;
    color: $black;
    -webkit-text-size-adjust: 100%;
    background: $white;
}

/*body.in_search {
    #main,
    .site-title,
    #primary-navigation,
    #colophon {
        opacity: 0.2;
    }
}*/

#page {
	width:100%;
	height:100%;
	margin:0 auto;
}

#main {
    width:100%;
    margin:0;
    margin-top:$header_h;
    background:$white;
    transition:margin 0.3s;
    
    .landingpage-noheader & {
        margin:0 !important;
    }
}

#primary {
    padding:0;
    
    .single-post & {
        transition:all 0.3s;
    }
}

#secondary {
    padding:0;
    
    .body-gatedcontent-preform & {
        display:none !important;
    }
    
    .single-career & {
        padding-top:70px;
    }
    
    .single-post & {
        //padding:0 40px 0 54px;
        padding:0;
    }
}

#content {
    position:relative;
    width:100%;
    //min-height:1800px; /****************** TEMP FOR DEV *****/
    margin:0 auto;
    background:none;
    box-sizing:border-box;
    z-index:10;
    overflow:hidden;
    
    > article {
        //padding-top:40px;
        
        > .gform_wrapper {
            padding-top:0;
            width:calc(100% - 80px);
            max-width:1206px;
            margin:0 auto 60px;
        }
    }
}

.content_section {
    position:relative;
    width:100%;
    /*overflow:hidden;*/
    text-align:left;
    
    &.content_wrapper {
        z-index:10;
    }
    
    &.blue {
        background: $blue;
        
        * {
            color:$white !important;
        }
    }
    
    &.white {
        background: $white;
    }
    
    &.gray {
        background: $gray;
    }
    
    &.invert {
        > * {
            color: $white;
        }
    }
    
    &.flex_layouts_wrap {
        //padding-bottom:60px;
    }
}

.content_section_inner {
    width:calc(100% - 40px);
    max-width:1420px;
    margin:0 auto;
    padding:60px 0;
    text-align:left;
    box-sizing:border-box;
    background-color: transparent;
    
    &.full {
        width:100%;
        max-width:none;
    }
    &.narrow {
        width:75%;
        max-width:calc(1420px * 0.75);
    }
    &.narrower {
        width:65%;
        max-width:calc(1420px * 0.65);
    }
    
    &.white {
        background: $white;
    }
    
    &.gray {
        background: $gray;
    }
    
    .flex_layouts {
        padding-bottom:0;
    }
}

.wysiwyg {
    text-align:left;
    
    &.narrow {
        width:80%;
        margin:0 auto;
    }
    &.narrower {
        width:65%;
        margin:0 auto;
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    body {
        font-size: 20px; //BODY FONT DROPS ON MOBILE!!!!
    }
    
    #main {
        margin:0;
        
        .home & {
            margin:0;
        }
    }
    
    #primary {
        float:none;
        width:100%;
        box-sizing:border-box;
        margin:0;
        //padding:40px 0;
    }
    #secondary {
        width:100%;
        margin-top:0;
        margin-bottom:60px;
        padding:0;
        
        .page-template-page-contact & {
            margin-top:50px;
        }
        
        .inner {
            min-height:0;
        }
    
        .single-post & {
            margin-top:3em;
            padding:0;
        }
    }
    
    .content_section {
        
    }
    
    .content_section_inner {
        padding-top:60px;
        padding-bottom:30px;
    }
    
    .content_section_inner,
    .content_section_inner.narrow,
    .content_section_inner.narrower {
        width:100%;
        padding-left:20px;
        padding-right:20px;
    }
    
}

@media screen and (max-width: 480px) {
    
    .content_section_inner,
    .content_section_inner.narrow,
    .content_section_inner.narrower {
        width:100%;
        padding-left:20px;
        padding-right:20px;
    }
    
}


@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #main {
        margin:0;
        
        .home & {
            margin:0;
        }
    }
    
    #secondary {
        
        .single-post & {
            padding:0;
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_full}) {
    
    .wysiwyg {
        
        /*br {
            display:none;
        }*/
        
        &.narrow {
            width:85%;
        }
        &.narrower {
            width:85%;
        }
    }
    
    .content_section_inner {
        
    }
    
    .content_section_inner.narrow,
    .content_section_inner.narrower {
        width:85%;
    }
    
}

@media screen and (min-width: 1025px) and (max-width: #{$bp_full}) {
    
    .content_section_inner {
        
    }
    
}

/* SPECIFIC FOR BRINGING IN MOBILE NAV AND HAMBURGER */
@media screen and (max-width: #{$bp_mid}) {
    
    body.mobile_nav_open {
        overflow:hidden;
    }
    
    #site-inner {
        position:relative;
    }
    
}