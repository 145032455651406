/**********************************************************************************************/
/***** SITEWIDE PAGINATION STYLES *************************************************************/
/**********************************************************************************************/
//NATIVE PAGINATION
.paging-navigation {
    text-align:center;
}

.pagination {
    position:relative;
    display:block;
	text-align: center;
	margin: 0 auto;
	padding: 40px 0;
	list-style: none;
	z-index: 1;
    
    .page-numbers {
        display: inline-block;
        width:auto;
        height:30px;
        margin:0;
        padding:0 16px;
        box-sizing:border-box;
        color: $ins_filter_gray;
        font-size:0.75em;
        line-height:30px;
        font-weight:$normal;
        border:none;
        text-decoration: none;
        transition:none;
                
        &:hover,
        &:focus {
            color: $blue;
        }
        
        &:active {
            color: $blue;
            font-weight:$bold;
        }
        
        &.current {
            color: $blue;
            cursor: default;
            font-weight:$bold;
        }
        
        &.dots {
            background:none;
            box-shadow:none;
            padding-right:0;
            padding-left:0;
            width:20px;
            font-weight:$normal;
            text-decoration: none;
        }
        
        &.prev,
        &.next {
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            margin:0;
            padding:0 0 0 24px;
            font-size:0.75em;
            font-weight:$normal;
            transition:none;
            
            &:before,
            &:after {
                position:absolute;
                left:0;
                top:50%;
                transform:translateY(-40%) rotate(180deg);
                width:17px;
                height:13px;
                background-color:transparent;
                background-repeat:no-repeat;
                background-position: center left;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 23'%3e%3cpolygon points='0.124971 9.79111 24.2125 9.79111 16.8661 2.44637 19.2588 0.0536499 30.7036 11.5 19.2588 22.9464 16.8661 20.5537 24.2125 13.209 0.124971 13.209' fill='%23b0b3b5'%3e%3c/polygon%3e%3c/svg%3e");
                background-size:cover;
                content:'';
            }
            
            &:hover {
                color:$blue;
                
                &:before,
                &:after {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31 23'%3e%3cpolygon points='0.124971 9.79111 24.2125 9.79111 16.8661 2.44637 19.2588 0.0536499 30.7036 11.5 19.2588 22.9464 16.8661 20.5537 24.2125 13.209 0.124971 13.209' fill='%2300aeef'%3e%3c/polygon%3e%3c/svg%3e");
                }
            }
        }
        
        &.prev {
            left:0;
            
            &:after {
                display:none;
            }
        }
        
        &.next {
            right:0;
            padding:0 24px 0 0;
            
            &:before {
                display:none;
            }
            
            &:after {
                position:absolute;
                left:auto;
                right:0;
                transform:translateY(-40%) rotate(0deg);
            }
        }
    }
}


@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}