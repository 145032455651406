/**********************************************************************************************/
/***** GLOBAL *********************************************************************************/
/**********************************************************************************************/


/* GRID ACCESSORIES */
.grid {
    > div {
        
        &.flush_right {
            
            > .cont {
                width:calc(100% + #{$gutter});
                margin-right:(-$gutter);
            }
        }
        
        &.flush_left {
            
            > .cont {
                width:calc(100% + #{$gutter});
                margin-left:(-$gutter);
            }
        }
        
        &.flush_left_right {
            padding-right:0;
            > .cont {
                width:calc(100% + #{$gutter} + #{$gutter});
                margin-left:(-$gutter);
                margin-right:(-$gutter);
            }
        }
        
        > .cont {
            
        }
    }
}

/*@media screen and (min-width: #{$bp_full}) {
    .grid > div.flush_right > .cont {
        width: calc(100% + (((100vw - #{$bp_full}) / 2) + #{$gutter}));
        margin-right: calc(-1 * (((100vw - #{$bp_full}) / 2) + #{$gutter}));
    }
    .grid > div.flush_left > .cont {
        width: calc(100% + (((100vw - #{$bp_full}) / 2) + #{$gutter}));
        margin-left: calc(-1 * (((100vw - #{$bp_full}) / 2) + #{$gutter}));
    }
}*/

/**********************************************************************************************/
/***** HOME PAGE *******************************************************************************/
/**********************************************************************************************/
#intro_cont {
    
    
    .cont {
        padding:0 84px 0 0;
        
        h2 {
            margin-top:0;
        }
    }
}

#ins_slider {
    h2 {
        margin:0;
    }
}

#cs_slider_metric {
    
    .content_section_inner {
        position:relative;
    }
    
    .see_all {
        position:absolute;
        bottom:50px;
        right:50px;
    }
    
    h2 {
        position:absolute;
        left:calc(8.333% + 20px);
        top:3.5em;
        z-index:10;
        color:$white;
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    #intro_cont {
    
    
        .cont {
            margin:0 0 40px;
            padding:0;
        }
    }
    
    #ins_slider {
        h2 {
            margin:0 0 40px;
        }
    }
    
    #cs_slider_metric {
        
        .content_section_inner {
            padding-bottom:100px !important;
        }
    
        .see_all {
            bottom:30px;
        }
    
        h2 {
            left:80px;
            top:2em;
            width:calc(100% - 160px);
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1025px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** ABOUT PAGE *******************************************************************************/
/**********************************************************************************************/



@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1025px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** SERVICES (LANDING, DETAIL) *************************************************************/
/**********************************************************************************************/
.services_list {
    margin:0 !important;
    padding:0 !important;
    list-style:none !important;
    
    > li {
        position:relative;
        margin:0;
        padding:0;
        list-style-type:none;
        
        &:last-of-type {
            
            p {
                margin:0;
            }
        }
    }
    
    .icon {
        position:absolute;
        left:-70px;
        top:5px;
        width:50px;
        height:60px;
        text-align:center;
        
        img {
            display:inline-block;
            max-width:50px;
            max-height:60px;
        }
    }
    
    h4 {
        margin:0;
    }
    
    p {
        
    }
}

.service_blocks {
    margin:0 0 40px;
    padding:0;
    list-style:none;
    
    > li {
        display:block;
        margin:50px 0 0;
        
        &:first-of-type {
            margin:30px 0 0;
        }
    }
    
    .inner {
        position:relative;
    }
    
    .bg_img {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-color:transparent;
        background-repeat:no-repeat;
        background-position:center top;
        background-size:cover;
        filter: grayscale(100%);
    }
    
    .overlay {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        @include background-opacity($blue, $opacity: 0.8);
    }
    
    .inner_content {
        position:relative;
        display:flex;
        align-items:flex-start;
        justify-content:flex-start;
        flex-direction:column;
        box-sizing:border-box;
        padding:60px 25% 90px calc(8.333% + 20px);
        
        * {
            color:$white;
        }
        
        ul,ol {
            margin:0 0 0 30px;
        }
    }
    
    .eyebrow {
        width:100%;
        margin:0 0 2em;
        
        span {
            display:inline-block;
            vertical-align:middle;
        }
        
        .icon {
            width:50px;
            height:auto;
            margin-left:20px;
            
            img {
                filter: brightness(0) invert(1);
            }
        }
    }
    
    .btn {
        margin:2em 0 0;
    }
}

.results_list {
    margin:0;
    padding:0;
    list-style:none;
    
    * {
        box-sizing:border-box;
    }
    
    > li {
        margin:0 0 80px;
    }
    
    .logos {
        display:flex;
        align-items:flex-start;
        justify-content:flex-start;
        flex-wrap: wrap;
        margin:0 0 20px;
        
        img {
            display:inline-block;
            margin:0 30px 10px 0;
            max-height:70px;
        }
    }
    
    .inner_content {
        
    }
    
    .client_label {
        display:inline-block;
        margin:0 30px 10px 0;
        color:$gray;
        font-size:1.2em;
        line-height:1.1em;
        font-weight:$bold;
    }
    
    h3 {
        margin:0 0 0.5em;
        font-size:34px;
        line-height:1.21em;
        transition:color 0.2s;
    }
    
    .btn {
        margin:0;
        color:$blue !important;
        
        &:before {
            background:$blue;
        }
                
        &:hover {
            color:$blue !important;
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    .service_blocks {
    
        .inner_content {
            padding:50px;
        }
    
        .eyebrow {
            margin:0;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** INSIGHTS LANDING ***********************************************************************/
/**********************************************************************************************/
#ins_landing {
    
    * {
        box-sizing:border-box;
    }
    
    #tertiary {
        padding:0 0 0 20px;
    }
    
    #ins_main {
        margin-top:0;
        padding:0 0 0 40px;
        
        .entry-content {
            margin-bottom:70px;
        }
    }
    
    #secondary {
        
        > div {
            width:100%;
            margin:0 0 0 -40px;
        }
    }
}

.insights-filter-clear {
    position:relative;
    display: inline-block;
    color: $blue;
    margin:0;
    padding:0 0 0 28px;
    font-weight:$semi;
    text-decoration: none;
    text-align: left;
    line-height: 22px;
    font-size: 16px;
    cursor:pointer;
    
    &:before {
        position:absolute;
        left:0;
        top:50%;
        transform:translateY(-50%);
        width:20px;
        height:20px;
        background:transparent url(../../images/close.svg) no-repeat center center;
        background-size:cover;
        content:'';
    }
    
    &:hover {
        opacity:0.5;
    }
}

.insights-filter-desc {
    margin:0 0 2em;
    font-size:16px;
    line-height:22px;
    font-weight:$semi;
    color:$ins_filter_gray_active;
    
    .insights-filter-clear {
        margin-left:30px;
    }
}

.insights-landing-filter {
    margin:0 0 40px;
    padding:0;
    list-style:none;
    
    .insights-filter-title {
        margin:0 0 1em;
        font-size:18px;
        font-weight:$xbold;
        color:$ins_filter_gray_active;
    }
    
    ul {
        margin:0 0 50px;
        padding:0;
        list-style:none;
        
        li {
            display:block;
            margin:0 0 15px;
            font-weight:$normal;
            
            &.current-cat {
                
                
                a {
                    color:$ins_filter_gray_active;
                    font-weight:$semi;
                    
                    &:before {
                        color:$ins_filter_gray_active;
                        background:$white;
                        box-shadow:inset 0 0 0 1px $ins_filter_gray_active;
                    }
                    
                    &:hover {
                        color:$ins_filter_gray_active;
                    }
                }
            }
        }
        
        a {
            position:relative;
            display:block;
            padding:0 0 0 28px;
            color:$ins_filter_gray;
            font-weight:$normal;
            font-size:16px;
            line-height:22px;
            
            &:before {
                position:absolute;
                left:0;
                top:2px;
                width:18px;
                height:18px;
                font-family: FontAwesome;
                line-height:18px;
                font-size:14px;
                text-align:center;
                color: $white;
                content:'\f00c';
                background:$white;
                box-shadow:inset 0 0 0 1px $ins_filter_gray;
            }
            
            &:hover {
                color:$ins_filter_gray_active;
                
                &:before {
                    box-shadow:inset 0 0 0 1px $ins_filter_gray_active;
                }
            }
        }
    }
}

.insight-list {
    
    article.card {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        position:relative;
        z-index:1000;
        margin:0 0 74px;
        padding:0;
        min-height:440px;
        width:100%;
        box-sizing:border-box;
        opacity:1;
        transition:opacity 0.3s;
        background-color:$gray;
        background-repeat:no-repeat;
        background-position:center top;
        background-size:cover;
        
        &:after {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            z-index:-1;
            background: -moz-linear-gradient(left,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(left,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
            background: linear-gradient(to right,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
            content:'';
        }
        
        a {
            color:$white !important;
        
            &:hover {
                opacity:0.7;
            }
        }
    
        .inner_content {
            padding:50px 10% 50px 50px;
            box-sizing:border-box;
            z-index:1002;
        }
    
        p {
            margin:0 0 24px;
            font-size:0.75em;
            color:$white;
        }
    
        .eyebrow.label {
            position:relative;
            margin:0 0 30px;
            padding:0 0 0 50px;
        
            &:before {
                position:absolute;
                left:0;
                top:50%;
                width:31px;
                height:31px;
                transform:translateY(-50%);
                background:transparent url(../../images/insight-icon-article-white.svg) no-repeat left center;
                background-size:auto;
                content:'';
            }
        
            &.podcast {
            
                &:before {
                    background-image:url(../../images/insight-icon-podcast-white.svg);
                }
            }
        
            &.whitepaper {
            
                &:before {
                    background-image:url(../../images/insight-icon-whitepaper-white.svg);
                }
            }
        
            &.webinar,
            &.video {
            
                &:before {
                    background-image:url(../../images/insight-icon-video-white.svg);
                }
            }
        }
    
        h3 {
            margin:0 0 24px;
            font-size:30px;
            color:$white;
        }
    
        .meta {
            margin:0 0 24px;
            color:$white;
            font-size:0.75em;
        
            a {
                text-decoration:underline;
                font-weight:$bold;
            }
        
            &.top {
                margin:0 0 30px;
            }
        }
        .btn {
            margin:1.5em 0 0;
        }
    }
}


@media screen and (max-width: #{$bp_small}) {
    
    #ins_landing {
        
        #tertiary {
            padding:0;
        }
    
        #ins_main {
            margin:50px 0;
            padding:0;
        }
    
        #secondary {
        
            > div {
                margin:0;
            }
        }
    }
    
    .insights-landing-filter {
    
        ul {
            
            li {
                display:inline-block;
                margin:0 20px 10px 0;
            }
        
            a {
                padding:0 0 0 24px;
            
                &:before {
                    left:0;
                }
            }
        }
    }
    
    .insight-list {
    
        article.card {
            margin:0 0 54px;
            min-height:300px;
        }
    
        .inner_content {
            flex:0 0 100%;
            padding:54px;
        }
    
        h3 {
            font-size:30px;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #ins_landing {
        
        #tertiary {
            padding:0;
        }
    
        #ins_main {
            margin:50px 0;
            padding:0;
        }
    
        #secondary {
        
            > div {
                margin-top:50px;
            }
        }
    }
    
    .insights-landing-filter {
    
        ul {
            
            li {
                display:inline-block;
                margin:0 20px 10px 0;
            }
        
            a {
                padding:0 0 0 24px;
            
                &:before {
                    left:0;
                }
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** INSIGHTS DETAIL ************************************************************************/
/**********************************************************************************************/
.single-post {
    
    #primary .rp4wp-related-posts {
        display:none !important;
    }
    
    .content_grid {
        position:relative;
        
        .crumb {
        
        
            &:before {
                display:block;
            }
        }
    }
    
    .entry-content {
        a {
            font-weight:$normal;
            color:$black;
            text-decoration:underline;
            text-decoration-thickness:0.08em;
            text-underline-offset:0.2em;
            
            &:hover {
                color:$blue;
                opacity:1;
            }
        }
    }
    
    .byline {
        margin:0 0 3em;
        font-size:0.833em;
        
        a {
            font-weight:$bold;
            color:$black;
            text-decoration:underline;
            text-decoration-thickness:0.08em;
            text-underline-offset:0.2em;
            
            &:hover {
                color:$blue;
                opacity:1;
            }
        }
    }
}

#author_block {
    margin:100px 0 50px;
    border-top:1px solid $gray;
    
    .body-gatedcontent-preform &,
    .body-webinar-preform & {
        display:none;
    }
    
    .author {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        padding:40px 0 0;
    }
    
    .img {
        flex:0 0 100px;
        margin:30px 20px 0 0;
        background-color:$blue;
        background-repeat:no-repeat;
        background-position:center top;
        background-size:cover;
        width:100px;
        height:100px;
        border-radius:50px;
    }
    
    .cont {
        flex:0 0 calc(100% - 120px);
        font-size:14px;
        line-height:1.57em;
    }
    
    h5 {
        
    }
    
    p {
        margin:0;
        font-size:14px;
        
        &.eyebrow {
            margin:0 0 10px;
            font-size:11px;
            text-transform:uppercase;
        }
        
        &.job_title {
            margin:0 0 1.5em;
        }
    }
}

//PODCAST
#transcript {
    margin:60px 0 0;
    padding:20px 0 0;
    border-top:1px solid #aeafb5;
    
    .inner {
        position:relative;
        overflow:hidden;
        transition:height 0.3s;
        
        &.short {
            height:280px;
        
            &:after {
                opacity:1;
                visibility:visible;
            }
        }
        
        &:after {
            position:absolute;
            left:0;
            bottom:0;
            width:100%;
            height:230px;
            background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
            background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
            transition:all 0.3s;
            content:'';
            z-index:1;
            opacity:0;
            visibility:hidden;
        }
    }
}

//GATED POSTS
/*#post_gate_teaser {
    margin:60px 0 120px;
        
    .inner {
        position:relative;
        overflow:hidden;
        
        &.short {
            height:280px;
        
            &:after {
                opacity:1;
                visibility:visible;
            }
        }
        
        &:after {
            position:absolute;
            left:0;
            bottom:0;
            width:100%;
            height:300px;
            background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
            background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%);
            content:'';
            z-index:1;
        }
    }
    
    .body-gatedcontent-postform & {
        display:none;
    }
}*/

.webinar-upcoming-form-date {
    
}

#access_gate {
    margin:1.67em -40px 1.67em;
    border:none;
    background:$blue;
    
    .inner {
        padding:60px;
    }
    
    #secondary & {
        margin:0;
    }
    
    .landing-page-thumbnail {
        margin:0 0 1em;
        
        img {
            display:block;
            width:100%;
        }
    }
    
    .landingpage-two-column & {
        
        .inner {
            padding:30px;
        }
        
        .gform_wrapper {
        
        
            .gform_fields {
                display:block;
                
                .gfield {
                    margin-top:1em;
                    
                    &.gfield_nfprivacy {
                        margin-top:1em !important;
                    }
                }
            }
        }
    }
    
    .contentupgrade-wrapper {
        margin:0 !important;
        
        .contentupgrade {
            padding:0 !important;
        }
    }
    
    * {
        color:$white !important;
    }
    
    .gform_wrapper {
        
        .gform_title {
            margin:0 0 0.75em;
            font-weight:$semi;
        }
    
        h3 {
            margin:0 0 0.75em;
        }
    
        .gform_description,
        .cust_desc {
            font-size:0.89em;
            font-weight:$semi;
        }
    
        &.cust_title {
            
            .gform_title {
                display:none !important;
            }
        }
        &.cust_desc {
        
            .gform_description {
                display:none !important;
            }
        }
    
        #content & {
            margin:0 !important;
            padding:0 !important;
    
            li {
                margin:2em 0 0 !important;
        
                &:first-of-type {
                    margin:0 !important;
                }
            }
    
            .gform_footer {
                margin:2em 0 0;
    
                .gform_button {
                    font-size:0.89em;
                    color:$white !important;
                    border-color:$white !important;
                    background-color:$blue !important;
        
                    &:hover {
                        color:$blue !important;
                        border-color:$blue !important;
                        background-color:$white !important;
                    }
                }
            }
        }
    }
}

/*.body-gatedcontent-preform #secondary .widget {
    display:none !important;
}
.body-gatedcontent-postform #secondary .widget {
    display:block !important;
}*/


@media screen and (max-width: #{$bp_small}) {
    
    .single-post {
        
        .content_grid {
            
        
            .crumb {
                position:relative;
                left:30px;
                top:0;
                margin:0 0 54px;
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** WORK LANDING ************************************************************************/
/**********************************************************************************************/
#work_grid {
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    flex-wrap: wrap;
    margin:1.5em 0 0;
    
    * {
        box-sizing:border-box;
    }
    
    > li {
        position:relative;
        z-index:1000;
        margin:0 0 20px;
        padding:0;
        //min-height:560px;
        align-self:stretch;
        box-sizing:border-box;
        //opacity:0.1;
        transition:opacity 0.3s;
        background-color:$gray;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
        
        .inner {
            height:100%;
        }
        
        .inner_content {
            height:100%;
        }
        
        &.metric {
            flex:0 0 calc(60% - 10px);
            align-items:flex-end;
            
            * {
                color:$white !important;
            }
            
            .inner {
                position:relative;
                //min-height:560px;
            }
    
            .bg_img {
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
                background-color:transparent;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
                filter: grayscale(100%);
            }
    
            .overlay {
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
                @include background-opacity($blue, $opacity: 0.8);
            }
    
            .inner_content {
                position:relative;
                display:flex;
                align-items:flex-start;
                justify-content:flex-start;
                flex-direction:column;
                box-sizing:border-box;
                padding:40px calc(8.333% + 20px) 62px 7%;
            }
    
            p {
                margin:0 0 0.5em;
                font-weight:$semi;
                color:$white;
        
                &.eyebrow {
                    width:100%;
                    margin:0 0 2em;
                }
            }
    
            .btn {
                margin:2em 0 0;
                
                &:hover {
                    color:$black !important;
                }
            }
            
        }
        
        &.test {
            flex:0 0 calc(40% - 10px);
            
            .inner {
                position:relative;
                //min-height:560px;
                background-color:$gray;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
                z-index:-1;
            }
            
            .inner_content {
                position:relative;
                padding:40px 7% 62px;
                box-sizing:border-box;
                @include background-opacity($trueblack, 0.6);
                z-index:1001;
            }
            
            .quote {
                margin:0 0 1.5em;
                font-size:24px;
                font-weight:$bold;
                text-indent:-15px;
                line-height:1.42em;
                color:$white;
            }
            
            .cite_wrap {
                display:flex;
                justify-content:flex-start;
                align-items:center;
            }
            
            .cite_hs {
                flex:0 0 70px;
                width:70px;
                height:70px;
                border-radius:50%;
                margin:0 14px 0 0;
                background-color:$gray;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
            }
            
            cite,
            .cite {
                flex:1 0 calc(100% - 84px);
                margin:0;
                padding:0;
                font-size:17px;
                font-style:normal;
                font-weight:$medium;
                line-height:1.35em;
                color:$white;
                
                &:before {
                    display:none;
                }
            }
            
            .btn {
                margin:3em 0 0;
            }
        }
        
        //CTA CALLOUTS
        &.span3,
        &.span2 {
            background-color:$white;
            
            * {
                color:$black;
            }
            
            .inner {
                display:flex;
                align-items:center;
                justify-content:flex-start;
            }
            
            .inner_content {
                height:auto;
                padding:40px calc(8.333% + 20px) 62px;
            }
            
            .btn {
                margin-top:2em;
            }
        }
        
        &.span3 {
            flex:0 0 100%;
            
            .inner_content {
                padding:4em calc(16.666% + 40px);
            }
        }
        
        &.span2 {
            flex:0 0 calc(60% - 10px);
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    #work_grid {
        display:block;
        
        > li {
            min-height:300px;
        
            &.metric {
                flex:0 0 100%;
            }
            
            &.span3,
            &.span2 {
                .inner_content {
                    padding:4em 50px;
                }
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** CASE STUDY DETAIL **********************************************************************/
/**********************************************************************************************/
#project_details {
    margin-bottom:100px;
    
    .content_section_inner {
        padding:66px 54px 40px;
    }
    
    .details {
        padding:0 40px 0 0;
        
        p {
            font-size:16px;
            font-weight:$bold;
        }
    }
    
    .info_cont {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        flex-wrap:wrap;
        padding:0 0 0 24px;
    }
    
    .info {
        flex:0 0 50%;
        margin: 0 0 50px;
        padding:0 20px 0 0;
        box-sizing:border-box;
        
        h5 {
            margin:10px 0 5px;
        }
        
        p {
            margin:0;
            font-size:16px;
            font-weight:$bold;
        }
    }
}

.content_section_inner {
    
    &.flex_layouts {
        
    }
    
    &.standard_content {
        padding-top:0;
        padding-bottom:0;
    }
    
    &.cont_cont_2col {
        
    }
    
    &.img_grid {
        
    }
    
    &.single_img {
        
    }
}

.flex_layouts {
    
    
    &.image_row {
        
        
        .images {
            display:flex;
            justify-content:flex-start;
            align-items:flex-start;
            
            &.count_2 {
                
            }
            
            &.count_3 {
                
                .img_wrap {
                    flex:0 0 calc(33.3333% - 14px);
                }
            }
            
            &.count_4 {
                
                .img_wrap {
                    flex:0 0 calc(25% - 15px);
                }
            }
            
            .img_wrap {
                flex:0 0 calc(50% - 10px);
                margin:0 0 20px 20px;
                
                &:first-of-type {
                    margin-left:0;
                }
            }
            
            .img {
                display:block;
                
                img {
                    display:block;
                    width:100%;
                }
            }
            
            .caption {
                margin:15px 0 0 0;
                padding:0 5%;
                font-size:16px;
                line-height:1.5em;
            }
        }
    }
    
    &.single_image {
        
        
        .img {
            display:block;
            position: relative;
            width: 100%;
            
            img {
                display: block;
                width: 100%;
            }
        }
        
        .caption {
            width:50%;
            margin:15px 0 0 50%;
            padding:0 5%;
            box-sizing:border-box;
            font-size:16px;
            line-height:1.5em;
        }
    }
    
    &.fii_team_grid {
        
    }
    
    .team_grid {
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        
        > li {
            flex:0 0 calc(33.3333% - 54px);
            margin:0 0 80px 80px;
            
            &:first-of-type {
                margin-left:0;
            }
        }
        
        .headshot {
            width:100%;
            height:0;
            padding-bottom:100%;
            margin:0 auto 30px;
            border-radius:50%;
            background-color:$light_gray;
            background-repeat:no-repeat;
            background-position:center top;
            background-size:cover;
        }
        
        p {
            text-align:center;
            
            &.name {
                @extend h2;
                margin:0 0 0.25em !important;
            }
            
            &.job_title {
                margin:0;
                font-size:0.96em;
            }
        }
    }
}

#RelatedServices {
    margin:100px 0;
    
    h2 {
        margin:0 0 60px;
        color: $black;
    }
    
    .related_cont {
        display:flex;
        justify-content:space-between;
        align-items:flex-start;
        
        > div {
            flex: 0 0 calc(50% - 50px);
        }
        
        .eyebrow {
            margin:0 0 10px;
            color:$gray;
            font-size:0.9em;
            line-height:1.44em;
            
            span {
                display:inline-block;
                vertical-align:middle;
                
                &.icon {
                    height:20px;
                }
            }
            
            svg {
                height:20px;
                width:auto;
                margin-right:10px;
                
                g {
                    fill: $gray;
                }
            }
        }
    }
}


@media screen and (max-width: #{$bp_small}) {
    
    .content_section_inner {
        
    }
    
    .flex_layouts {
        
        .team_grid {
            display:block;
        
            > li {
                width:80%;
                max-width:300px;
                margin:0 auto 80px;
            
                &:first-of-type {
                    margin-left:auto;
                }
            }
        }
    }
    
    #RelatedServices {
        margin:60px 0;
    
        h2 {
            
        }
    
        .related_cont {
        
            > div {
                flex: 0 0 calc(50% - 30px);
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
    #RelatedServices {
        
        h2 {
            
        }
    
        .related_cont {
            display:block;
        
            > div {
                margin:0 0 50px;
            }
        }
    }
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}


/**********************************************************************************************/
/***** CONTACT PAGE ***************************************************************************/
/**********************************************************************************************/


@media screen and (max-width: #{$bp_small}) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}


/**********************************************************************************************/
/***** SINGLE TEAM MEMBER *********************************************************************/
/**********************************************************************************************/

.single-teammember {
    
    .headshot_cont {
        padding:0 66px 0 0;
    }
    
    .headshot {
        width:100%;
                
        .inner {
            width:100%;
            padding-bottom: 100%;
            border-radius:50%;
            overflow:hidden;
            background-color:$light_gray;
            background-repeat:no-repeat;
            background-position:center top;
            background-size:cover;
        }
    }
    
    h1 {
        margin:0 0 0.2em;
    }
    
    .job_title {
        font-size:1.2em;
        font-weight:$bold;
    }
    
    .social {
        display:inline-block;
        position:relative;
        width:21px;
        margin:0 34px 0 0;
        background: none;
        text-indent:-9999px;
        overflow:hidden;
        vertical-align:top;
        transition:all 0.3s;
        color: $blue;
        
        &.tw {
            width:25px;
        }
        
        &.yt {
            width:29px;
        }
        
        &.vm {
            width:24px;
        }
        
        span {
            display:block;
            text-align:left;
        }
        
        svg {
            display:block;
            position:absolute;
            left:50%;
            top:0;
            margin:0;
            height:21px;
            transition: all 0.2s;
            transform:translateX(-50%);
            
            .shape {
                transition: all 0.3s;
                fill: $blue;
            }
        }
        
        &:hover {
            svg .shape {
                fill: rgba(0,0,255,0.7);
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {
    
    .single-teammember {
    
        .headshot_cont {
            padding:0;
        }
    
        .headshot {
            max-width:400px;
            margin:0 auto 30px;
        }
    
        h1 {
            text-align:center;
        }
    
        .job_title {
            text-align:center;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}

/**********************************************************************************************/
/***** NEWFANGLED CONTENT UPGRADES, LANDING PAGES *********************************************/
/**********************************************************************************************/
.contentupgrade-wrapper {
    margin:3em -20px;
    background:$blue;
    border:none;
    
    #secondary & {
        margin:2em 0;
    }
    
    .contentupgrade {
        padding:60px;
        
        * {
            color: $white !important;
        }
        
        p {
            font-size:0.75em;
        }
        
        #secondary & {
            padding:30px;
        }
        
        .contentupgrade-title {
            margin:0 0 1em;
            font-weight:$xbold;
            font-size:30px;
                line-height:1.33em;
        }
        
        .contentupgrade-preform {
            .gform_title {
                margin:0 0 1em;
                font-weight:$xbold;
                font-size:30px;
                line-height:1.33em;
            }
        
            h3 {
                margin:0 0 1em;
                font-size:30px;
                line-height:1.33em;
            }
        
            .gform_description,
            .cust_desc {
                font-size:18px;
            }
        
            &.cust_title {
                
                .gform_title {
                    display:none !important;
                }
            }
            &.cust_desc {
            
                .gform_description {
                    display:none !important;
                }
            }
        
            #content & {
        
                .gform_wrapper {
                    margin:0 !important;
                    padding:0 !important;
            
                    li {
                        margin:2em 0 0 !important;
                
                        &:first-of-type {
                            margin:0 !important;
                        }
                    }
            
                    .gform_footer {
                        margin:2em 0 0;
            
                        .gform_button {
                            font-size:0.89em;
                            color:$white !important;
                            border-color:$white !important;
                            background-color:$blue !important;
        
                            &:hover {
                                color:$blue !important;
                                border-color:$blue !important;
                                background-color:$white !important;
                            }
                        }
                    }
                }
            }
        }
    }
}