/**********************************************************************************************/
/***** WIDGETS ********************************************************************************/
/**********************************************************************************************/
#secondary {
    
    .widget {
        margin:0 0 140px;
        padding:0;
        border:none;
        
        //GATED, WEBINAR CONTENT
        .widget-smartcta {
            
            .panel__inner {
                
            }
            
            .widget-title {
                margin:0 0 30px;
            }
            
            .panel__image {
                margin:0 0 20px;
                overflow:hidden;
                background:none;
                                
                a {
                    display:block;
                    position:relative;
                    overflow:hidden;
                    
                    &:hover {
                        
                        img {
                            
                        }
                    }
                }
                
                img {
                    display:block;
                    width:100%;
                }
            }
            
            .panel__content {
                background:none;
                margin:0;
                padding:0;
                
                p {
                    
                }
            }
            
            .entry-summary {
                margin:0.75em 0 0;
                
                strong,b {
                    font-weight:$bold;
                }
            }
            
            .btn {
                margin:2em 0 0;
            }
        }
        
        &.widget_rp4wp_related_posts_widget {
            padding:0;
            border:none;
        }
    }
    
    .widget-title {
        font-weight:$xbold;
        color:$black;
        margin:0 0 0.5em;
        
        a {
            color:$black;
            
            &:hover {
                color:$blue;
            }
        }
        
        &.initial {
            margin-bottom:0.25em;
        }
    }
    
    p {
        font-size:16px;
        
        &.meta {
            margin:0 0 0.5em;
            
            a {
                color:$black;
                
                &:hover {
                    color:$blue;
                }
            }
        }
        
        &.form_desc {
            margin:0 0 1.5em;
            font-size:24px;
            line-height:1.5em;
        }
    }
    
    .rp4wp-related-posts {
        overflow:visible;
    }
    
    .rp4wp-posts-list {
        float:none;
        margin:30px 0 0 !important;
        padding:0 !important;
        border:none;
        
        li {
            
        }
        
        a {
            position:relative;
            display:block;
            margin:0 0 30px;
            padding:3px 0 0 52px;
            width:calc(100% - 52px);
            font-size:16px;
            line-height:24px;
            font-weight:$normal;
            color: $black;
            text-decoration:none;
            
            &:hover {
                color:$blue;
            }
            
            &:before {
                position:absolute;
                left:0;
                top:0;
                width:30px;
                height:30px;
                background:transparent url(../../images/insight-icon-article.svg) no-repeat left center;
                background-size:30px auto;
                content:'';
            }
            
            &.podcast {
                
                &:before {
                    background-image:url(../../images/insight-icon-podcast.svg);
                }
            }
            
            &.whitepaper {
                
                &:before {
                    background-image:url(../../images/insight-icon-whitepaper.svg);
                }
            }
            
            &.webinar,
            &.video {
                
                &:before {
                    background-image:url(../../images/insight-icon-video.svg);
                }
            }
        }
    }
}

@media only screen and (max-width: #{$bp_small}) {
    
    #secondary {
    
        .widget {
            margin:0 0 50px;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}