/**********************************************************************************************/
/***** FORM STYLES ****************************************************************************/
/**********************************************************************************************/


#content {
    .gform_wrapper {
        padding:10px 0;
    
        * {
            box-sizing:border-box;
        }
    
        .gform_fields {
            margin:0 !important;
            padding:0 !important;
            list-style:none !important;
            grid-column-gap: 56px;
            grid-row-gap: 1.5em;
        
            li,
            .gfield {
                position:relative;
                width:100%;
                margin:0;
                padding:0;
                vertical-align:top;
                text-align:left;
                list-style:none !important;
                z-index:100;
                
                /*&:first-of-type {
                    margin-top:2em;
                }*/
            
                &.msg {
                    margin-top:20px;
        
                    label,
                    .gfield_label {
                        left:0;
                        width:100%;
                    }
                }
                
                &.gfield_html {
                    margin-bottom:3em;
                }
            
                &:before {
                    display:none !important;
                }
            
                &.gform_hidden {
                    margin:0 !important;
                }
                
                &.recaptcha_field {
                    margin:0 !important;
                }
                
                &.field_sublabel_below.hidden_label {
                    margin:0 !important;
                }
                
                &.gfield_nfprivacy {
                    margin-top:0 !important;
                }
            
            }
        }
    
        .gfield_label,
        .ginput_complex label {
            display:block;
            width:100%;
            margin:0 0 6px;
            padding:0;
            font-size:14px;
            font-weight:$medium;
            color: $black;
            line-height:1.21em;
            text-transform:uppercase;
            letter-spacing:0.05em;
            z-index:106;
            user-select:none;
            pointer-events:none;
            white-space: nowrap;
            transition:all 0.3s;
        
            .gfield_required {
                position: relative;
                display:inline-block;
                top:0;
                margin-left:0;
                font-size: 14px;
                color: $black;
            }
            
            &.gfield_label_before_complex {
                margin:0 0 2em;
                padding:0;
                font-size:16px;
                color: $light_gray;
                
                .gfield_required {
                    
                }
            }
            
            #secondary &,
            .page-template-page-contact & {
                
            }
        
        }
    
        input[type='text'],
        input[type='number'],
        input[type='tel'],
        textarea {
            width:100%;
            padding:8px 40px 8px 15px;
            border:none;
            height:50px;
            border-radius:0;
            border:none;
            font-family:$inter;
            font-weight:$normal;
            font-size:16px;
            line-height:34px;
            border:none;
            -webkit-appearance:none;
            letter-spacing:normal;
            color:rgba(34,34,34,0.8);
            background:$form_bg_gray;
            transition:background 0.3s;
        
            &:focus {
                outline:none;
                @include background-opacity($blue, $opacity: 0.1);
            }
        }
    
        input[type='text'] {
            
        }
    
        textarea {
            display:block;
            padding-right:15px;
            height:80px;
            line-height:1.3em;
        }
    
        input[type='hidden'] {
            display:none;
        }
    
        .field_sublabel_below {
        
            .ginput_container label {
                font-size:14px;
                font-style:italic;
                color: $black;
            }
        }
    
        .instruction {
            padding:0;
            font-size:14px;
            line-height:1.3em;
            font-style:italic;
            color: $black;
        }
    
        .ginput_container_select,
        .ginput_container_text,
        .ginput_container_email,
        .ginput_container_phone,
        .ginput_container_date,
        .ginput_container_website,
        .ginput_container_number {
            margin:0;
            border:none;
        }
    
        .ginput_container_textarea {
            margin:0;
        }
        
        .ginput_container_select,
        .ginput_complex .address_state {
            position:relative;
            cursor:pointer;
            background:$form_bg_gray;
            z-index:105;
        
            &:after {
                position:absolute;
                right:40px;
                top:50%;
                transform:translateY(-50%);
                content:'';
                text-align:center;
                z-index:-1;
                width: 12px; 
                height: 7px; 
                background: transparent url('../../images/select-caret.svg') no-repeat center center;
                background-size:100% auto;
            }
        
            select {
                border: none;
                outline: 0;
                margin:0;
                padding:8px 40px 8px 15px;
                font-size:16px;
                font-weight: $normal;
                line-height:34px;
                color:rgba(34,34,34,0.8);
                width:100%;
                background:none;
                border-radius:0;
                -webkit-user-select: none;
                -moz-user-select: -moz-none;
                -ms-user-select: none;
                user-select: none;
                -webkit-appearance: none;
                -moz-appearance: radio-container;
                appearance: none;
                white-space:pre-wrap;
                z-index:110;
                cursor:pointer;
            }
        
            select::-ms-expand {
                display: none;
            }
        }
        
        .ginput_container_radio {
            margin-top:0;
        }
        
        .gfield_radio {
            position: relative;
            cursor: pointer;
            margin:0;
            padding:0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        
            > li {
                display:inline-block;
                width:auto !important;
                margin:0;
                vertical-align:top;
                font-size:100% !important;
            }
            
            label {            
                display:inline-block;
                /*display:flex;
                align-items:center;
                justify-content:center;*/
                position: relative;
                margin:0 0 5px;
                padding: 0 25px;
                font-size: 14px !important;
                font-weight:$normal !important;
                height:1.2em;
                max-width:none !important;
                line-height:1em;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: $black !important;
        
                &:before {
                    position:absolute;
                    left:3px;
                    top:18px;
                    width:15px;
                    height:15px;
                    border-radius:50%;
                    content:'';
                    box-shadow: inset 15px 15px $blue;
                    transition: 0.2s transform ease-in-out;
                    transform: scale(0);
                }
                
                &:after {
                    position:absolute;
                    left:0;
                    top:15px;
                    width:20px;
                    height:20px;
                    border-radius:50%;
                    border:1px solid $black;
                    content:'';
                    z-index:-1;
                }
            }
        
            input[type='radio'] {
                position:absolute;
                opacity:0;
                cursor:pointer;
        
                &:checked {
            
                    ~ label:before {
                        transform: scale(1);
                    }
                }
            }
        }
        
        .gfield_checkbox {
            position: relative;
            cursor: pointer;
            margin:0;
            padding:0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        
            > li {
                display:inline-block;
                width:auto !important;
                margin:0 !important;
                vertical-align:top;
                font-size:100% !important;
            }
        
            label {
                display:inline-block;
                /*display:flex;
                align-items:center;
                justify-content:center;*/
                position: relative;
                margin:0;
                padding: 0 20px 0 25px;
                font-size: 14px !important;
                font-weight: $normal !important;
                height:1.2em;
                max-width:none !important;
                line-height:1.2em;
                font-style:normal !important;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: $black !important;
                
                &:before {
                    position:absolute;
                    left:0;
                    top:50%;
                    width:15px;
                    height:15px;
                    font-family: FontAwesome;
                    line-height:15px;
                    font-size:14px;
                    color: $gray;
                    content:'\f00c';
                    border:1px solid $black;
                    background:none;
                    text-align:center;
                    transform:translateY(-50%);
                }
            }
        
            input[type='checkbox'] {
                position:absolute;
                opacity:0;
                cursor:pointer;
            
                &:checked {
                
                    ~ label:before {
                        //background-image: url(../../images/checkmark-on.png);
                        color: $blue;
                    }
                }
            }
        
            &:hover {
            
            }
        
        }
        
        //HIDE DEFAULT UPLOAD BTN AND CUSTOMIZE
        .file_upload_label {
            position:relative;
            
            > .gfield_label {
                position:relative !important;
            
                &:after {
                    display:none;
                    /*position:absolute;
                    right:0;
                    top:50%;
                    transform:translateY(-50%);
                    width:20px;
                    height:20px;
                    content:'';
                    background:transparent url(../../images/file-upload.svg) no-repeat center center;
                    background-size:cover;
                    cursor: pointer;
                    transition:opacity 0.3s;*/
                }
                
                &:hover {
                    &:after {
                        opacity:0.5;
                    }
                }
            }      
        }
        
        .ginput_container_fileupload {
            position:relative;
            
            [type="file"] {
                overflow: hidden;
                height:30px;
                line-height:30px;
                box-sizing:border-box;
                padding:0 0 5px;
                border:none;
                white-space: nowrap;
            }
            
            .gform_fileupload_rules {
                margin:6px 0 0;
                padding:0;
                font-size:12px;
                font-style:italic;
                line-height:1.4em;
            }
        }
        
        .no_label {
            .gfield_label {
                display:none;
            }
            .gfield_checkbox label {
                position:relative;
                font-size:14px;
                color: $black;
                
                &:after {
                    position:absolute;
                    width:10px;
                    height:10px;
                    display:inline-block;
                    top: -0.25em;
                    font-size: 80%;
                    color: $black;
                    content:'*';
                }
            }
        }
        
        .gfield_contains_required.gfield_nfprivacy {
            .gfield_required {
                display:none !important;
            }
        }
    
        .nfprivacy-field-description {
            font-size:14px;
            line-height:1.3em;
            font-weight:$normal;
            color:$black;
        }
        
        .charleft,
        .ginput_counter {
            display:none !important;
        }
        
        .gfield_error {
            border:none;
            background:none;
            
            input[type='text'],
            select {
                
            }
        }
        
        .gform_title {
            @extend h2;
            margin:0 0 0.5em;
        }
        
        .gform_description {
            margin:0;
            font-weight:$medium;
            font-size:0.75em;
            line-height:1.3em;
        }
        
        .gform_validation_errors {
            margin:0 0 30px;
            
            h2.gform_submission_error {
                color:$blue;
                font-size:14px;
            }
        }
        
        .gform_required_legend {
            margin:10px 0 30px 0;
            font-size:12px;
            font-style:italic;
            font-weight:$medium;
            
            .gfield_required {
                color:$light_gray;
            }
        }
        
        .gfield_error {
            input[type='text'],
            input[type='number'],
            input[type='tel'],
            textarea,
            select {
                border-bottom:1px solid $blue;
            }
        }
        
        .validation_message {
            background:none;
            border:none;
            color:$blue;
            padding:0;
            margin:0;
            font-size:14px;
            font-weight: $normal;
            font-style:normal;
            line-height:1.3em;
            
            &.instruction {
                position:absolute;
                right:32px;
                margin:0;
                padding-left:0;
            }
        }
    
        .gform_confirmation_wrapper {
            text-align:center;
        
        }
        .gform_confirmation_message {
            
        }
        
        &.gform_validation_error {
            
            .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
                max-width:100% !important;
            }
        }
        
        /*.grecaptcha-badge {
            right:auto !important;
            left:10px;
            position:static !important;
        }*/
        
        .gform_footer {
            margin:0;
            padding:0;
            text-align:left;
            
            .gform_button {
                margin:0;
                padding:10px 30px;
                font-size:18px;
                text-decoration:none;
                border:1px solid $blue;
                color:$blue;
                font-weight:$bold;
                cursor:pointer;
                transition: all 0.3s;
                
                &:hover {
                    color: $white !important;
                    border-color:$blue !important;
                    background-color:$blue !important;
                }

                &:focus {
                    outline:none;
                }
            }
        }
    }
    
    .gform_confirmation_wrapper {
        text-align:center;
    
    }
    .gform_confirmation_message {
        
    }
    
    //CONTACT PAGE
    #contact_form {
        .gform_wrapper {
            .gform_fields {
                grid-column-gap: 0;
            }
        }
    }
    
    //SIDEBAR
    #secondary {
        
        .hbspt-form {
            
            form {
                grid-row-gap:1em;
            }
        }
        
        .gform_wrapper {
            
            .gform_fields {
                grid-template-columns:minmax(0,1fr);
                
                li,
                .gfield {
                    
                }
            }
            
            .gform_required_legend {
                margin-top:0;
            }
            
            .gform_title {
                @extend h3;
                margin:0 0 0.5em;
            }
            
            .gfield_checkbox {
                
                label {
                    
                    &:before {
                        color:$white;
                    }
                }
                
                input[type='checkbox'] {
                    
                    &:checked {
                
                        ~ label:before {
                            color: $blue;
                        }
                    }
                }
                
            }
            
            .nfprivacy-field-description {
                
            }
            
            .gform_description {
                margin-bottom:10px;
                font-size:16px;
                line-height:1.5em;
                font-weight: $normal;
            }
            
            .gform_footer {
                margin:1em 0 0;
            
                .gform_button {
                    margin:0;
                    font-size:16px;
                
                    &:hover {
                        
                    }
                }
            }
        }
    }
    
    /* HUBSPOT RAW HTML FORMS (duplication of existing form styles for Hubspot markup) */

    .hbspt-form {
        padding-top:10px 0;
        
        form {
            display:grid;
            grid-template-columns:repeat(12,1fr);
            margin:0 !important;
            padding:0 !important;
            grid-column-gap:0;
            grid-row-gap:1.5em;
            width:100%;
        }
    
        * {
            box-sizing:border-box;
        }
        
        .hs-form-field {
            position:relative;
            display:inline-block;
            width:100%;
            margin:0 0 20px;
            vertical-align:top;
            list-style:none;
            grid-column:span 12;
        
            &:before {
                display:none !important;
            }
            
            .entry-content & {
                
                .hs-form-field {
                    grid-column:span 6;
            
                    &.hs-fieldtype-textarea {
                        width: 100%;
                        grid-column:span 12;
                    }
            
                    &.hs-fieldtype-checkbox {
                        grid-column:span 12;
                    }
                }
            }
        
            &.hs-fieldtype-text {
                /*margin-left:90px;
                width: calc(50% - 45px);*/
                
            
                &:nth-child(odd) {
                    //margin-left:0;
                }
            }
        
            &.hs-fieldtype-textarea {
                width: 100%;
                grid-column:span 12;
            }
            
            &.hs-fieldtype-checkbox {
                grid-column:span 12;
                
                .inputs-list {
                    position: relative;
                    cursor: pointer;
                    margin:0;
                    padding:0;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
        
                    > li {
                        display:inline-block;
                        width:auto !important;
                        margin:0 !important;
                        vertical-align:top;
                        font-size:100% !important;
                    }
        
                    label {
                        display:inline-block;
                        position: relative;
                        margin:0;
                        padding: 0 20px 0 0;
                        font-size: 14px !important;
                        font-weight: $normal !important;
                        height:1.2em;
                        max-width:none !important;
                        line-height:1.2em;
                        font-style:normal !important;
                        cursor: pointer;
                        white-space: nowrap;
                        -webkit-user-select: all;
                        -moz-user-select: all;
                        -ms-user-select: all;
                        user-select: all;
                        pointer-events:all;
                        color: $black !important;
                
                        /*&:before {
                            position:absolute;
                            left:0;
                            top:50%;
                            width:15px;
                            height:15px;
                            font-family: FontAwesome;
                            line-height:15px;
                            font-size:14px;
                            color: $gray;
                            content:'\f00c';
                            border:1px solid $black;
                            background:none;
                            text-align:center;
                            transform:translateY(-50%);
                        }*/
                        
                        &.hs-error-msg {
                            padding:5px 0;
                            color:$blue !important;
                            font-size:14px !important;
                            text-transform:none;
                        }
                        
                        span {
                            background:none !important;
                        }
                    }
        
                    input[type='checkbox'] {
                        //position:absolute;
                        //opacity:0;
                        margin:0 5px;
                        cursor:pointer;
            
                        &:checked {
                
                            /*~ label:before {
                                color: $blue;
                            }*/
                        }
                    }
                }
                
                
            }
            
            &.hs-fieldtype-select {
                
                .input {
                    position:relative;
                    cursor:pointer;
                    background:$form_bg_gray;
                    z-index:105;
                    
                    &:after {
                        position:absolute;
                        right:40px;
                        top:50%;
                        transform:translateY(-50%);
                        content:'';
                        text-align:center;
                        z-index:-1;
                        width: 12px; 
                        height: 7px; 
                        background: transparent url('../../images/select-caret.svg') no-repeat center center;
                        background-size:100% auto;
                    }
                }
        
                select {
                    border: none;
                    outline: 0;
                    margin:0;
                    padding:8px 40px 8px 15px;
                    font-size:16px;
                    font-weight: $normal;
                    line-height:34px;
                    color:rgba(34,34,34,0.8);
                    width:100%;
                    background:none;
                    border-radius:0;
                    -webkit-user-select: none;
                    -moz-user-select: -moz-none;
                    -ms-user-select: none;
                    user-select: none;
                    -webkit-appearance: none;
                    -moz-appearance: radio-container;
                    appearance: none;
                    white-space:pre-wrap;
                    z-index:110;
                    cursor:pointer;
                }
        
                select::-ms-expand {
                    display: none;
                }
                
            }
        }
        
        label {
            display:block;
            width:100%;
            margin:0 0 6px;
            padding:0;
            font-size:14px;
            font-weight:$medium;
            color: $black;
            line-height:1.21em;
            text-transform:uppercase;
            letter-spacing:0.05em;
            z-index:106;
            user-select:none;
            pointer-events:none;
            white-space: wrap;
            transition:all 0.3s;
        
            .hs-form-required {
                
            }
        
            
        }
    
        input[type='text'],
        input[type='email'],
        input[type='number'],
        input[type='tel'],
        textarea {
            width:100%;
            padding:8px 40px 8px 15px;
            border:none;
            height:50px;
            border-radius:0;
            border:none;
            font-family:$inter;
            font-weight:$normal;
            font-size:16px;
            line-height:34px;
            border:none;
            -webkit-appearance:none;
            letter-spacing:normal;
            color:rgba(34,34,34,0.8);
            background:$form_bg_gray;
            transition:background 0.3s;
        
            &:focus {
                outline:none;
                @include background-opacity($blue, $opacity: 0.1);
            }
        }
    
        input[type='text'] {
        
        }
    
        textarea {
            height:80px;
                    
            #secondary & {
                
            }
        }
    
        input[type='hidden'] {
            display:none;
        }
    
        
    
        .hs-submit {
            grid-column:span 12;
        }
    
        .hs-error-msgs {
            margin:0;
            padding:0;
            list-style:none;
        
            > li {
                margin:0;
                padding:0;
                list-style-type:none;
            
                :marker {
                    display:none;
                }
            }
        
            .hs-error-msg {
                padding:5px 0;
                color:$blue;
                font-size:14px;
                text-transform:none;
            }
        }
    
        
    }
    
    .entry-content {
        
        .hbspt-form {
            
            form {
                margin:2.5em 0 !important;
                padding:0 !important;
                grid-column-gap:56px;
            }
        
            .hs-form-field {
                grid-column:span 6;
                
                &.hs-fieldtype-textarea {
                    width: 100%;
                    grid-column:span 12;
                }
    
                &.hs-fieldtype-checkbox {
                    grid-column:span 12;
                }
            }
        }
    }
}

::-webkit-input-placeholder {
  color: rgba(0,0,255,0.7);
}
::-moz-placeholder {
  color: rgba(0,0,255,0.7);
}
:-ms-input-placeholder {
  color: rgba(0,0,255,0.7);
}
:-moz-placeholder {
  color: rgba(0,0,255,0.7);
}

#footer_form,
.inline-cta,
#access_gate,
#secondary .contentupgrade {
    
    h2,
    h3,
    p,
    .gform_description {
        color: $black;
    }
    
    .gform_confirmation_message {
        margin:4em 0;
        @extend h3;
    }
    
    h2 {
        margin:0;
    }
    
    .gform_wrapper,
    .hbspt-form {        
        
        input[type='text'],
        input[type='email'],
        input[type='number'],
        input[type='tel'],
        textarea,
        .ginput_container_select,
        .ginput_complex .address_state,
        .hs-form-field.hs-fieldtype-select .input {
            background:$white;
        }
        
        .hs-form-field {
            grid-column:span 6;
            
            &.hs-fieldtype-textarea {
                width: 100%;
                grid-column:span 12;
            }
            
            &.hs-fieldtype-checkbox {
                grid-column:span 12;
            }
        }
    }
    
    .hbspt-form {
        
        form {
            grid-column-gap:56px !important;
        }
    }
}

//ALL ACCESS GATES (BLUE BG - GATED, CONTENT UPGRADE)
#content {
    #access_gate,
    .contentupgrade,
    #secondary .contentupgrade {
    
        .gform_wrapper {
    
            input[type='text'],
            input[type='number'],
            input[type='tel'],
            textarea {
                color:$black !important;
                background:$white;
                
                &:focus {
                    @include background-opacity($white, $opacity: 0.8);
                }
            }
            
            .ginput_container_select,
            .ginput_complex .address_state {
                background:$white;
                
                select {
                    color:$black !important;
                }
            }
            
            .gfield_radio {
                
                label {            
                    color: $white !important;
        
                    &:before {
                        box-shadow: inset 15px 15px $white;
                    }
                
                    &:after {
                        border:1px solid $white;
                    }
                }
            }
            
            .gfield_checkbox {
                
                label {
                    color: $white !important;
                    
                    &:before {
                        color: $blue;
                        border:1px solid $white;
                        background: $blue;
                    }
                }
        
                input[type='checkbox'] {
                    
                    &:checked {
                
                        ~ label:before {
                            color: $white;
                        }
                    }
                }
        
            }
        }
    }
    
    //MAIN COLUMN CU ONLY
    .contentupgrade {
        .gform_wrapper {
            
            .gform_fields {
                grid-column-gap:40px;
            }
            
        }
    }
    
    #secondary .gform_wrapper {
        
        .gform_fields {
            display:block;
            
            .gfield {
                margin-top:1em;
                
                &.gfield_nfprivacy {
                    margin-top:1em !important;
                }
            }
        }
    }
}

#secondary .gform_wrapper,
.page-template-page-contact .gform_wrapper {
    
    margin:0;
    padding:0;
    
    h2,
    .gform_title {
        margin:0 0 0.25em;
    }
    
    p,
    .gform_description {
        display:block;
    }
}

//FOOTER FORM, FLOATING
#content #footer_form {
    padding:0;
    
    .page-template-page-results & {
        .form_wrap {
            padding:60px 15% 92px;
        }
    }
    
    .form_wrap {
        margin-bottom: 150px;
        padding:60px 82px 92px;
        background:$gray;
    }
    
    .custom_title,
    p,
    .gform_description {
        color:$black;
    }
    
    .custom_title {
        @extend h2;
        margin:0 0 0.5em;
    }
    
    .gform_description {
        margin:0 0 1.5em;
        font-weight:$medium;
        font-size:0.75em;
        line-height:1.3em;
    }
    
    .gform_fields {
        
        li,
        .gfield {
            margin:0;
            
            &.revealed {
                margin-top:2em;
            }
            
            &.gfield_html {
                margin-top:2em;
            }
        
        }
    
    }
}

//CUSTOMIZE THE LOADING SPINNER
.nf-ajax-loading {
    position:absolute;
    left:50%;
    top:50%;
    margin:-20px 0 0 -20px;
    padding:0;
    width:40px !important;
    height:40px !important;
    min-height:0 !important;
    background-image: url(../../images/loading.svg);
    background-size:40px 40px !important;
    border:none !important;
    background-repeat:no-repeat;
    background-position:center center;
}

.gform_ajax_spinner {
    position:absolute;
    right:0;
    top:0;
	margin-left:0px !important;
	border:none !important;
	width: 40px !important;
	height: 40px !important;
	
	#secondary & {
	    right:-15px;
	    top:5px;
	    width: 30px !important;
	    height: 30px !important;
	}
}

#nfprivacy-modal {
    
    .nfprivacy-modal-body-inner {
        padding:80px 20px 20px;
    }
    
    .nfprivacy-modal-close,
    .nfprivacy-modal-confirm {
        display:inline-block;
        border-radius: 0px;
        margin:0 0 20px 20px;
        padding:10px 30px;
        font-size:0.75em;
        font-weight:$bold;
        text-decoration:none;
        border:1px solid $blue;
        color:$blue;
        background-color:transparent;
        text-align: center;
        line-height:1.2em;
        cursor:pointer;
        transition:all 0.3s;
        
        &.nfprivacy-modal-close-top {
            position:absolute;
            top:20px;
            right:20px;
            margin:0;
        }
            
        &:before {
            display:none;
        }
    
        &:hover {
            color: $white !important;
            border-color:$blue !important;
            background-color:$blue !important;
        }
    }
    
}



//WEBINAR ACCESS CODE
.webinar-past-form-access-code {
    
    input[type='text'] {
        width:50%;
        max-width:200px;
        padding:8px 15px;
        height:50px;
        border-radius:0;
        border:none;
        font-family:$inter;
        font-weight:$medium;
        font-size:16px;
        line-height:34px;
        border:none;
        -webkit-appearance:none;
        letter-spacing:normal;
        color:rgba(34,34,34,0.8);
        background:$white;
        transition:background 0.3s;
    
        &:focus {
            outline:none;
            @include background-opacity($blue, $opacity: 0.1)
        }
    }
}

@media only screen and (max-width: #{$bp_mid}) {
    
    #content {
        .gform_wrapper {
    
            .gform_fields {
                grid-column-gap: 30px;
            }
        }
        
        .contentupgrade {
            .gform_wrapper {
            
                .gform_fields {
                    display:block;
                    
                    .gfield,
                    li {
                        margin-top:1em;
                    }
                }
            
            }
        }
        
        .entry-content {
        
            .hbspt-form {
            
                form {
                    grid-column-gap:0;
                }
        
                .hs-form-field {
                    grid-column:span 12;
                }
            }
        }
    }
    
    #footer_form,
    #access_gate {
    
        .gform_wrapper,
        .hbspt-form {        
        
            .hs-form-field {
                grid-column:span 12;
            }
        }
    
        .hbspt-form {
        
            form {
                grid-column-gap:0 !important;
            }
        }
    }
    
}

@media only screen and (max-width: #{$bp_small}) {
    
    #content {
        .gform_wrapper {
    
            .gform_fields {
                grid-column-gap: 10px;
            }
        }
    }
    
    //FOOTER FORM, FLOATING
    #content #footer_form {
        
        .form_wrap {
            margin-bottom: 100px;
            padding:50px 50px 50px;
        }
        
        .page-template-page-results & {
            .form_wrap {
                padding:50px;
            }
        }
    }
    
}

@media only screen and (max-width: 479px) {
    
    //FOOTER FORM, FLOATING
    #content #footer_form {
        
        .form_wrap {
            padding:50px 20px 50px;
        }
    }
    
}