/**********************************************************************************************/
/***** TYPOGRAPHY *****************************************************************************/
/**********************************************************************************************/
* {
    ::-moz-selection {
        background: rgba(0,174,239,0.4);
    }
    ::selection {
        background: rgba(0,174,239,0.4);
    }
}

hr {
    height: 1px;
    @include background-opacity($black, $opacity: 0.2);
    border: none;
    margin: 1.54em 0;
}

a {
	text-decoration:none;
	color: $blue;
	transition:all 0.3s;
	
	&:hover {
	    //opacity:0.5;
	}
}

h1,h2,h3,h4,h5,h6 {
    margin:1.5em 0 0.5em;
    font-family: $inter;
    font-weight:$xbold;
    line-height:1.3em;
    color: $black;
    
    &.h1 {
        @extend h1;
    }
    
    &.h2 {
        @extend h2;
    }
}
h1,
p.heading {
    margin:0 0 1.5em;
    font-size: 1.75em; /* 42px */    
    
    .home #hero & {
        margin:0 0 10px;
        font-size: 4.17em; /* 100px */
        line-height:1em;
    }
}

h2 {
    
    font-size: 1.25em; /* 30px */
}

h3 {
    font-size:0.96em; /* 23px */
    
    &.h1 {
        font-size: 1.75em;
    }
}

h4 {
    font-size:0.96em;
    font-weight:$semi;
}

h5 {
    font-size:0.96em;
    font-weight:$medium;
    text-transform:uppercase;
}
h6 {
    font-size:1em;
    font-weight:$normal;
}

sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.55em;
    line-height: 0;
    font-size: 60%;
}
sub { 
    top: 0.55em; 
}

.wysiwyg {
    p {
        margin:0 0 1em;
        font-size:1em;
        
        &.h2 {
            @extend h2;
        }
        
        &.h4 {
            @extend h4;
        }
        
        &.gray {
            color:$light_gray;
        }
        
        &.gform_title {
            margin:3em 0 0.5em !important;
        }
        
        .btn {
            margin-bottom:0 !important;
        }
    }
    ul,
    ol {
        margin: 1em 0 1em 30px;
        list-style:decimal;
        
        &.list_3col,
        &.list_2col {
            margin:2em 0;
            list-style:none;
            column-count: 3;
            column-gap: 50px;
            break-inside:avoid;
        }
        
        &.list_2col {
            column-count: 2;
        }
    }
    ul {
        padding:0;
        list-style:disc;
    }
    ul li,
    ol li {
        display: list-item; 
        margin-bottom:0.25em;
        font-size:1em;
    }
    ol li {
        list-style: decimal;
    }
        
    ul li {
        position:relative;
        //list-style:none;
    }
    
    dl {
        @extend p;
        margin-top:1.5em;
    
        dt {
            font-weight:$xbold;
        }
    
        dd {
            margin:0 0 1em;
            font-style:italic;
        }
    }
    
    .invert & {
        
    }
    
    a {
        
        &:hover {
            opacity:0.5;
        }
    }
    
    p:last-of-type {
        //margin:0;
    }
    
    &.plast {
        
        p:first-of-type {
            margin-bottom:1.5em !important;
        }
        
        p:last-of-type {
            margin:0 !important;
        }
    }
}

p {
    margin-bottom:1.5em;
    line-height:1.5em;
    
    strong, b {
        font-family:$inter;
        font-weight:$bold;
    }
    
    &.h2 {
        @extend h2;
    }
    
    &.h4 {
        @extend h4;
    }
    
    &.gray {
        color:$light_gray;
    }
    
    &.gform_title {
        margin:3em 0 0.5em !important;
    }
    
    &.eyebrow {
        margin:0 0 1.75em;
        font-size:0.75em;
        font-weight:$medium;
        color:$white;
        letter-spacing:0.2em;
        text-transform:uppercase;
    }
}

.crumb {
    position:relative;
    display:block;
    margin:0 0 3em;
    color: $black;
    font-weight:$normal;
    font-size:14px;
    text-transform:uppercase;
    letter-spacing:0.2em;
    
    &:before {
        display:none;
        position:absolute;
        left:-26px;
        top:50%;
        transform:translateY(-50%) rotate(180deg);
        width:17px;
        height:13px;
        background:transparent url(../../images/link-arrow-blue.svg) no-repeat center left;
        background-size:cover;
        content:'';
        transition:left 0.3s;
    }
    
    &:hover {
        color: $black;
        opacity:1 !important;
        
        &:before {
            left:-36px;
        }
    }
}

strong, b {
    font-weight: $bold;
}
i, em {
    font-style:italic;
}

blockquote {
    position:relative;
    font-family:$inter;
    font-size:40px; /* 40px */
    line-height:1.425em;
    font-weight:$bold;
    margin:0;
    padding:0;
    width:100%;
    border:none;
    color: $blue;
    text-align:left;
    
    p {
        margin:0;
    }
    
    .wysiwyg & {
        margin:1.5em 0 1.5em -20px;
        width:90%;
    }
}
cite,
p.cite {
    position:relative;
    display:block;
    //@extend p;
    font-family:$inter;
    font-size:24px;
    font-style:italic;
    line-height:1.14em;
    font-weight:$medium;
    text-align:left;
    margin:1em 0 0;
    padding-left:30px;
    color: $blue;
    
    &:before {
        position:absolute;
        left:0;
        top:0.5em;
        width:20px;
        height:2px;
        background:$blue;
        content:'';
    }
}

img {
	vertical-align: bottom;
	
	.wysiwyg & {
	    max-width:100%;
	    height:auto;
	}
}
.screen-reader-text {
    clip:rect(1px, 1px, 1px, 1px);
    border:0;
    -webkit-clip-path:inset(50%);
    clip-path:inset(50%);
    width:1px;
    height:1px;
    margin:-1px;
    overflow:hidden;
    position:absolute !important;
    font-family: $inter;
    font-size:0.89em;
    font-weight: $normal;
    color: $black;
    z-index:10000;
    opacity:0;
    transition:opacity 0.2s;
    word-wrap:normal !important;
}
.site-navigation .screen-reader-text {
    left:auto;
    right:10px;
}
.screen-reader-text:focus {
    clip:auto;
    opacity:1;
}

@media only screen and (max-width: #{$bp_small}) {
    
    h1,
    p.heading {
        
        .home #hero & {
            font-size:3em;
        }
    }
    
    .wysiwyg {
        ul,
        ol {
            
            &.list_3col,
            &.list_2col {
                column-count: auto !important;
            }
        }
    }
    
    blockquote {
        font-size:30px;
    
        .wysiwyg & {
            margin:1.5em 0;
            width:100%;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {

    
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}