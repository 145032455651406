/**********************************************************************************************/
/***** FOOTER *********************************************************************************/
/**********************************************************************************************/
#colophon.site-footer {
    position:relative;
    padding:60px 0 84px;
    background: $light_gray;
    font-size:14px;
    line-height:1.7em;
    font-weight:$normal;
    color:$trueblack;
    
    .footer_inner {
        margin:0 auto;
        width:calc(100% - 84px);
        max-width:1420px;
    }
    
    .logo {
        display:flex;
        justify-content:flex-start;
        align-items:center;
        height:100%;
        max-width:100%;
        margin:0 0 80px;
        padding-right:60px;
        z-index:1000;
        box-sizing:border-box;
        vertical-align:top;
        transition:margin 0.5s linear;
        transform:none;
        
        a {
            display:block;
            flex:0 0 212px;
            position:relative;
            margin-right:30px;
            outline:none;
            
            img {
                display:block;
                width:100%;
            }
        }
        
        .tagline {
            flex:0 0 calc(100% - 242px);
            font-size:14px;
            line-height:1.36em;
            font-weight:$medium;
            color:$trueblack;
        }
    }
    
    .info {
        a {
            display:inline-block;
            margin:0 20px 0 0;
            
            &.email {
                text-decoration:underline;
            }
        }
    }
    
    .copyright {
        margin:0;
        font-weight:$medium;
    }
    
    #menu-footer-legal-nav {
        display:block;
        margin:0;
        padding:0;
        
        li {
            display:inline-block;
            margin:0;
            
            a {
                display:block;
                margin:0 10px 0 0;
                padding:0;
                color:$trueblack;
                font-weight:$medium;
                text-decoration:underline;
                transition:all 0.2s;
                
                &:hover {
                    color:$blue;
                }
            }
        }
    }
    
    .soc {
        text-align:right;
    }
    
    .social_cont {
        padding-top:30px;
    }
    
    .social {
        display:inline-block;
        position:relative;
        width:22px;
        margin:0 0 0 18px;
        background: none;
        text-indent:-9999px;
        overflow:hidden;
        vertical-align:top;
        transition:all 0.3s;
        color: $white;
        
        &.tw {
            width:25px;
        }
        
        span {
            display:block;
            text-align:left;
        }
        
        svg {
            display:block;
            position:absolute;
            left:50%;
            top:0;
            margin:0;
            height:21px;
            transition: all 0.2s;
            transform:translateX(-50%);
            
            .shape {
                transition: all 0.3s;
                fill: $footer_icon_gray;
                fill-opacity:1;
            }
        }
        
        &:hover {
            svg .shape {
                fill-opacity:0.7;
            }
        }
    }
    
    .partners {
        display:flex;
        justify-content:flex-end;
        align-items:center;
        height:30px;
        
        a,
        div {
            flex:0 0 auto;
            align-self:stretch;
            display:block;
            margin:0;
            padding:0 0 0 30px;
            color: $white;
            opacity:1;
            transition:all 0.2s;
            text-decoration:underline;
            text-decoration-thickness:0.1em;
            text-underline-offset:0.2em;
            
            img {
                display:block;
                height:100%;
            }
        }
    }
}

@media only screen and (max-width: #{$bp_small}) {
	
	#colophon.site-footer {
	    padding:50px 0;
        
        .footer_inner {
            width:calc(100% - 40px);
            
            &#footer_bottom {
                text-align:center;
            }
        }
        
        .logo {
            margin:0 0 40px;
            padding-right:0;
        
            a {
                flex:0 0 130px;
                margin-right:10px;
            }
        
            .tagline {
                flex:0 0 calc(100% - 140px);
                font-size:12px;
            }
        }
        
        .soc {
            margin:0 0 40px;
            text-align:center;
        }
        
        .social_cont {
            padding-top:0;
        }
    
        .social {
            margin:0 9px;
        }
        
        .info {
            margin:0 0 40px;
            
            a {
                display:block;
                margin:0;
            }
        }
        
        .partners {
            flex-wrap:wrap;
            height:auto;
            justify-content:center;
        
            a,
            div {
                flex:0 0 auto;
                align-self:auto;
                height:30px;
                margin:0 0 20px;
                padding:0 15px;
            }
        }
        
        .copyright {
            margin:0 0 40px;
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    #colophon.site-footer {
        
        .footer_inner {
            width:calc(100% - 40px);
        }
    
        .logo {
            padding-right:0;
        
            a {
                flex:0 0 130px;
                margin-right:10px;
            }
        
            .tagline {
                flex:0 0 calc(100% - 140px);
                font-size:12px;
            }
        }
        
        .partners {
            height:25px;
        }
    }
    
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    
    #colophon.site-footer {
        
        .footer_inner {
            width:calc(100% - 40px);
        }
    
        .logo {
            padding-right:0;
        
            a {
                flex:0 0 150px;
                margin-right:20px;
            }
        
            .tagline {
                flex:0 0 calc(100% - 170px);
                font-size:12px;
            }
        }
        
        .partners {
            height:25px;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}