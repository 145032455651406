/**********************************************************************************************/
/***** SITEWIDE TABLE STYLES ******************************************************************/
/**********************************************************************************************/
//GENERAL TABLE STYLES
table {
    width:100%;
    margin: 1em 0; 
    border: 1px solid $black; 
    border-top: none; 
    table-layout:auto;
    
    td,
    th { 
        border-left: 1px solid $black; 
        padding: 1em;
        background:$light_gray;
    }
    th { 
        font-weight:$semi;
        @include background-opacity($blue, $opacity: 0.4);
    }

    tr {
        border-top: 1px solid $black; 
        border-left: 1px solid $black;
        
        &:nth-child(even) td {
            @include background-opacity($blue, $opacity: 0.2);
        }
    }
    
}

//INLINE WYSIWYG TABLE STYLES (ADDED VIA THE CLASSIC MCE EDITOR)
.wysiwyg {
    
    table {
        border: 1px solid $black; 
        border-top: none; 
        table-layout:auto;
        
        td,
        th { 
            border-left: 1px solid $black; 
            padding: 1em;
            background:$light_gray;
            
            &:first-of-type {
                width:auto !important;
            }
        }
        
        th { 
            font-weight:$semi;
            @include background-opacity($blue, $opacity: 0.4);
        }
        
        tr {
            border-top: 1px solid $black; 
            border-left: 1px solid $black;
            
            &:nth-child(even) td {
                @include background-opacity($blue, $opacity: 0.2);
            }
        }
    }
}

@media screen and (max-width: #{$bp_small}) {

    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}