/**********************************************************************************************/
/***** HEADER *********************************************************************************/
/**********************************************************************************************/
#mobile_nav_toggle {
    display:none;
    position:absolute;
    right:0;
    top:52px;
    width:30px;
    height:20px;
    margin:0 auto;
    cursor: pointer;
    outline:none;
    background:none;
    z-index:1010;
    transition:all 0.3s;
    
    &.open {
        top:52px;
    }
    
    .inner {
        position:absolute;
        right:0;
        top:0px;
        width:30px;
        height:20px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
    }
}
#mobile_nav_toggle span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $blue;
    border-radius:0;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}
#mobile_nav_toggle.open span {
    background: $white;
}
#mobile_nav_toggle span:nth-child(1) {
    top: 0px;
}
#mobile_nav_toggle span:nth-child(2),
#mobile_nav_toggle span:nth-child(3) {
    top: 9px;
}
#mobile_nav_toggle span:nth-child(4) {
    top: 18px;
}
#mobile_nav_toggle.open span:nth-child(1) {
    top: 9px;
    width: 0%;
    left: 50%;
}
#mobile_nav_toggle.open span:nth-child(2) {
  transform: rotate(45deg);
}
#mobile_nav_toggle.open span:nth-child(3) {
  transform: rotate(-45deg);
}
#mobile_nav_toggle.open span:nth-child(4) {
    top: 9px;
    width: 0%;
    left: 50%;
}

#mobile_nav_tray {
    display:none;
    position:fixed;
    top:0;
    bottom:0;
    width:100%;
    height:100vh;
    padding:140px 34px 60px;
    box-sizing:border-box;
    overflow:scroll;
    z-index:990;
    @include background-opacity($blue, 0.95);
    
    .mobile_nav_open & {
        
    }
    
    .inner {
        height:100%;
        overflow:scroll;
    }
}

.site-header {
    //position:absolute;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    background:$white;
    height:$header_h;
    transition:all 0.3s linear;
    z-index:1000;
    
    .home & {
        
    }
    
    .mobile_nav_open & {
        background:none;
    }
    
    .landingpage-noheader & {
        display:none;
    }
    
    .grid {
        height:100%;
        
        > div {
            height:100%;
        }
    }
    
    .grid-pad {
        padding-top:0;
    }
    
    &.scrolling {
        height:130px;
    }
}

.header_inner {
    position:relative;
    width:calc(100% - 84px);
    max-width:1420px;
    height:100%;
    margin:0 auto;
    box-sizing: border-box;
}

.site-title {
    display:flex;
    justify-content:flex-start;
    align-items:center;
    height:100%;
    max-width:100%;
    margin:0;
    padding-right:60px;
    z-index:1000;
    box-sizing:border-box;
    vertical-align:top;
    transition:margin 0.5s linear;
    transform:none;
    
    .scrolling & {
        
        .mobile_nav_open & {
            
        }
        
        > a {
            
        }
    }
    
    .mobile_nav_open & {
        
    }
    
    > a {
        display:block;
        flex:0 0 212px;
        position:relative;
        margin-right:30px;
        
        img {
            display:block;
            width:100%;
        }
        
        &:hover {
            text-decoration:none;
        }
    }
    
    .tagline {
        flex:0 0 calc(100% - 242px);
        font-size:14px;
        line-height:1.36em;
        font-weight:$medium;
        color:$trueblack;
    }
}

.header_right {
    margin:0;
    padding:0;
    display:flex;
    justify-content:flex-end;
    align-items:center;
    flex-wrap:nowrap;
    height:100%;
    //z-index:1000;
    transition:all 0.3s linear;
}

@media only screen and (max-width: #{$bp_mid}) {

    #mobile_nav_toggle {
        display:block;
    }
    
    .site-header {
        position:relative;
        height:$header_h_mobile;
    }
    
    .site-title {
        padding:0;
        
        > a {
            flex:0 0 auto;
            width:130px;
            margin-right:10px;
        }
        
        .tagline {
            flex:0 0 calc(100% - 140px);
            font-size:12px;
        }
    }
    
    .header_inner {
        width:calc(100% - 40px);
    }
    
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    
    
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    
    .site-title {
        padding:0;
        
        > a {
            flex:0 0 auto;
            width:150px;
            margin-right:20px;
        }
        
        .tagline {
            flex:0 0 calc(100% - 170px);
            font-size:12px;
        }
    }
    
}


@media only screen and (max-width: 767px) {
    
    
    
}

@media only screen and (max-width: 480px) {
    
    
    
}