//HEADER HEIGHT
$header_h:  210px;
$header_h_mobile:  130px;

// COLOR
$trueblack: #000000;
$black: #222222;
$white: #ffffff;
$gray: #efefef;
$form_bg_gray: #f2f2f2;
$light_gray: #f9f9f9;
$blue: #00aeef;
$footer_icon_gray: #4c4c4c;

$ins_filter_gray: #b0b3b5;
$ins_filter_gray_active: #878787;

@mixin background-opacity($color, $opacity: 0.3) {
    background-color: $color; /* The Fallback */
    background-color: rgba($color, $opacity);
}

// Gutenberg color options
// -- see editor-color-palette in functions.php
$colors: (
    'white'         : #ffffff,
    'black'         : #222222,
    'gray'          : #efefef,
    'light_gray'    : #f9f9f9,
    'blue'          : #00aeef,
);

@each $name, $color in $colors {

	.has-#{$name}-color {
		color: $color;
	}

	.has-#{$name}-background-color {
		background-color: $color;
	}
}

/*@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
}*/

/**** SITE FONTS ARE TYPEKIT-HOSTED **********************************************/

$inter: 'Inter', sans-serif;

$normal: 400;
$medium: 500;
$semi: 600;
$bold: 700;
$xbold: 800;

/*
  Simple Grid
  Project Page - http://thisisdallas.github.com/Simple-Grid/
  Author - Dallas Bass
  Site - http://dallasbass.com
*/

$gutter: 20px; //col width is 84 at full width
$bp_full: 1420px;
$bp_mid: 1023px;
$bp_small: 767px;

[class*='grid'],
[class*='col-'],
[class*='mobile-'],
.grid:after {
	box-sizing: border-box;	
}

[class*='col-'] {
	float: left;
  	min-height: 1px;
	padding-right: $gutter; /* column-space */
}

[class*='col-'] [class*='col-']:last-child {
	padding-right: 0;
}

.grid {
	width: 100%;
	max-width:1420px;
	min-width: 708px; /* when using padded grid on ipad in portrait mode, width should be viewport-width - padding = (768 - 20) = 748. actually, it should be even smaller to allow for padding of grid containing element */
	margin: 0 auto;
	/*overflow: hidden;*/
	
	&.extra_wide {
	    max-width:1420px;
	}
	&.full {
	    max-width:none;
	}
}

.grid:after {
	content: "";
	display: table;
	clear: both;
}

.grid-pad {
	padding-top: 0;
	padding-left: $gutter; /* grid-space to left */
	padding-right: 0; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-20px=0 */
}

.push-right {
	float: right;
}

@media screen and (max-width: #{$bp_full}) {
    
    
    
}

/* Content Columns */

.col-1-1 {
	width: 100%;
}
.col-2-3, .col-8-12 {
	width: 66.66%;
}

.col-1-2, .col-6-12 {
	width: 50%;
}

.col-1-3, .col-4-12 {
	width: 33.33%;
}

.col-1-4, .col-3-12 {
	width: 25%;
}

.col-1-5 {
	width: 20%;
}

.col-1-6, .col-2-12 {
	width: 16.667%;
}

.col-1-7 {
	width: 14.28%;
}

.col-1-8 {
	width: 12.5%;
}

.col-1-9 {
	width: 11.1%;
}

.col-1-10 {
	width: 10%;
}

.col-1-11 {
	width: 9.09%;
}

.col-1-12 {
	width: 8.33%
}

/* Layout Columns */

.col-11-12 {
	width: 91.66%
}

.col-10-12 {
	width: 83.333%;
}

.col-9-12 {
	width: 75%;
}

.col-5-12 {
	width: 41.66%;
}

.col-7-12 {
	width: 58.33%
}

/* Pushing blocks */
.push-2-3, .push-8-12 {
	margin-left: 66.66%;
}

.push-1-2, .push-6-12 {
	margin-left: 50%;
}

.push-5-12 {
    margin-left: 41.66%;
}

.push-1-3, .push-4-12 {
	margin-left: 33.33%;
}

.push-1-4, .push-3-12 {
	margin-left: 25%;
}

.push-1-5 {
	margin-left: 20%;
}

.push-1-6, .push-2-12 {
	margin-left: 16.667%;
}

.push-1-7 {
	margin-left: 14.28%;
}

.push-1-8 {
	margin-left: 12.5%;
}

.push-1-9 {
	margin-left: 11.1%;
}

.push-1-10 {
	margin-left: 10%;
}

.push-1-11 {
	margin-left: 9.09%;
}

.push-1-12 {
	margin-left: 8.33%
}

/* Pulling blocks */
.pull-2-3, .pull-8-12 {
	margin-left: -66.66%;
}

.pull-1-2, .pull-6-12 {
	margin-left: -50%;
}

.pull-5-12 {
    margin-left: -41.66%;
}

.pull-1-3, .pull-4-12 {
	margin-left: -33.33%;
}

.pull-1-4, .pull-3-12 {
	margin-left: -25%;
}

.pull-1-5 {
	margin-left: -20%;
}

.pull-1-6, .pull-2-12 {
	margin-left: -16.667%;
}

.pull-1-7 {
	margin-left: -14.28%;
}

.pull-1-8 {
	margin-left: -12.5%;
}

.pull-1-9 {
	margin-left: -11.1%;
}

.pull-1-10 {
	margin-left: -10%;
}

.pull-1-11 {
	margin-left: -9.09%;
}

.pull-1-12 {
	margin-left: -8.33%
}

@media handheld, only screen and (max-width: #{$bp_small}) {
	.grid {
		width: 100%;
		min-width: 0;
		margin-left: 0;
		margin-right: 0;
		/*padding-left: 20px;*/ /* grid-space to left */
		/*padding-right: 10px;*/ /* grid-space to right: (grid-space-left - column-space) e.g. 20px-10px=10px */
		padding-left: 0;
		padding-right: 0;
		
		.site-header & {
		    
		}
		
		.site-footer & {
		    padding-left:20px;
		    padding-right:20px;
		    
		    .grid {
		        padding:0 20px;
		    }
		}
		
		&.extra_wide {
            max-width:none;
            padding:0 20px;
            
            .site-header & {
                padding:0 20px;
            }
            
            .site-footer & {
                padding:0;
            }
        }
        
        &.full {
	        max-width:none;
	        padding:0;
	    }
	}

	[class*='col-'] {
		width: auto;
		float: none;
		margin: 0;
		padding-left: 0;
		/*padding-right: 10px;*/ /* column-space */
		padding-right: 0;
	}

	[class*='col-'] [class*='col-'] {
		padding-right: 0;
	}

	/* Mobile Layout */

	[class*='mobile-col-'] {
		float: left;
		margin: 0;
		padding-left: 0;
		padding-right: 10px; /* column-space */
		padding-bottom: 0;
	}

	.mobile-col-1-1 {
		width: 100%;
	}
	.mobile-col-2-3, .mobile-col-8-12 {
		width: 66.66%;
	}

	.mobile-col-1-2, .mobile-col-6-12 {
		width: 50%;
	}

	.mobile-col-1-3, .mobile-col-4-12 {
		width: 33.33%;
	}

	.mobile-col-1-4, .mobile-col-3-12 {
		width: 25%;
	}

	.mobile-col-1-5 {
		width: 20%;
	}

	.mobile-col-1-6, .mobile-col-2-12 {
		width: 16.667%;
	}

	.mobile-col-1-7 {
		width: 14.28%;
	}

	.mobile-col-1-8 {
		width: 12.5%;
	}

	.mobile-col-1-9 {
		width: 11.1%;
	}

	.mobile-col-1-10 {
		width: 10%;
	}

	.mobile-col-1-11 {
		width: 9.09%;
	}

	.mobile-col-1-12 {
		width: 8.33%
	}

	/* Layout Columns */

	.mobile-col-11-12 {
		width: 91.66%
	}

	.mobile-col-10-12 {
		width: 83.333%;
	}

	.mobile-col-9-12 {
		width: 75%;
	}

	.mobile-col-5-12 {
		width: 41.66%;
	}

	.mobile-col-7-12 {
		width: 58.33%
	}
	
}

@media only screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
//@media only screen and (min-width: 768px) and (max-width: 1200px) {
    
    .grid {
		width: 100%;
		min-width: 0;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px; /* grid-space to left */
		padding-right: 10px; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-10px=10px */
		
		&.even_pad {
	        padding-right: 20px;
	    }
	    
	    &.full {
	        max-width:none;
	        padding:0;
	    }
	    
	    .site-header & {
	        padding:0;
	    }
		
		.site-footer & {
		    		    
		    padding:0;
		}
	}
    
    [class*='tablet-col-'] {
		float: left;
		margin: 0;
		padding-left: 0;
		padding-right: 10px; /* column-space */
		padding-bottom: 0;
		
		.even_pad & {
		    padding-right: 0;
		}
		
		#footer_cta & {
		    float:none;
		    margin:0 auto;
		}
		
		.hero & {
		    float:none;
		    margin:0 auto;
		}
		
	}

	.tablet-col-1-1 {
		width: 100%;
	}
	.tablet-col-2-3, .tablet-col-8-12 {
		width: 66.66%;
	}

	.tablet-col-1-2, .tablet-col-6-12 {
		width: 50%;
	}

	.tablet-col-1-3, .tablet-col-4-12 {
		width: 33.33%;
	}

	.tablet-col-1-4, .tablet-col-3-12 {
		width: 25%;
	}

	.tablet-col-1-5 {
		width: 20%;
	}

	.tablet-col-1-6, .tablet-col-2-12 {
		width: 16.667%;
	}

	.tablet-col-1-7 {
		width: 14.28%;
	}

	.tablet-col-1-8 {
		width: 12.5%;
	}

	.tablet-col-1-9 {
		width: 11.1%;
	}

	.tablet-col-1-10 {
		width: 10%;
	}

	.tablet-col-1-11 {
		width: 9.09%;
	}

	.tablet-col-1-12 {
		width: 8.33%
	}

	/* Layout Columns */

	.tablet-col-11-12 {
		width: 91.66%
	}

	.tablet-col-10-12 {
		width: 83.333%;
	}

	.tablet-col-9-12 {
		width: 75%;
	}

	.tablet-col-5-12 {
		width: 41.66%;
	}

	.tablet-col-7-12 {
		width: 58.33%
	}
	
	/* Pushing blocks */
    .tablet-push-2-3, .tablet-push-8-12 {
        margin-left: 66.66%;
    }

    .tablet-push-1-2, .tablet-push-6-12 {
        margin-left: 50%;
    }

    .tablet-push-5-12 {
        margin-left: 41.66%;
    }

    .tablet-push-1-3, .tablet-push-4-12 {
        margin-left: 33.33%;
    }

    .tablet-push-1-4, .tablet-push-3-12 {
        margin-left: 25%;
    }

    .tablet-push-1-5 {
        margin-left: 20%;
    }

    .tablet-push-1-6, .tablet-push-2-12 {
        margin-left: 16.667%;
    }

    .tablet-push-1-7 {
        margin-left: 14.28%;
    }

    .tablet-push-1-8 {
        margin-left: 12.5%;
    }

    .tablet-push-1-9 {
        margin-left: 11.1%;
    }

    .tablet-push-1-10 {
        margin-left: 10%;
    }

    .tablet-push-1-11 {
        margin-left: 9.09%;
    }

    .tablet-push-1-12 {
        margin-left: 8.33%
    }
    
}

@media only screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    .grid {
		width: 100%;
		min-width: 0;
		margin-left: 0;
		margin-right: 0;
		padding-left: 20px; /* grid-space to left */
		padding-right: 10px; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-10px=10px */
		
		&.full {
	        max-width:none;
	        padding:0;
	    }
	
		&.even_pad {
	        padding-right: 20px;
	    }
	    
	    .site-header & {
	        padding-left: 0;
		    padding-right: 0;
		    margin:0 auto;
	    }
	}
}

@media only screen and (min-width: 1421px) {
    
    
    
}