/**********************************************************************************************/
/***** SLIDERS ********************************************************************************/
/**********************************************************************************************/
.image_slider {
    margin:1em 0 0;
    min-height:416px;
    
    * {
        box-sizing:border-box;
    }
    
    .slick-list {
        margin:0 -10px;
        padding:0 !important;
        //overflow:visible !important;
    }
    
    .slick-slide {
        position:relative;
        z-index:1000;
        margin:0;
        padding:0 10px;
        width:100%;
        min-height:416px;
        box-sizing:border-box;
        //opacity:0.1;
        transition:opacity 0.3s;
        background-color:$gray;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:cover;
        
        &:hover,  
        &.slick-current {
            opacity:1;
        }
    }
    
    &.cs {
        
        &.testimonial {
            
            .slick-slide {
                background:none;
            }
            
            
            .inner {
                position:relative;
                min-height:416px;
                background-color:$gray;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
                z-index:-1;
            }
            
            .inner_content {
                position:relative;
                padding:46px 80px 62px;
                box-sizing:border-box;
                @include background-opacity($trueblack, 0.6);
                z-index:1001;
            }
            
            .quote {
                margin:0 0 1.5em;
                font-size:24px;
                font-weight:$bold;
                text-indent:-15px;
                line-height:1.42em;
                color:$white;
            }
            
            .cite_wrap {
                display:flex;
                justify-content:flex-start;
                align-items:center;
            }
            
            .cite_hs {
                flex:0 0 70px;
                width:70px;
                height:70px;
                border-radius:50%;
                margin:0 14px 0 0;
                background-color:$gray;
                background-repeat:no-repeat;
                background-position:center top;
                background-size:cover;
            }
            
            cite,
            .cite {
                flex:1 0 calc(100% - 84px);
                margin:0;
                padding:0;
                font-size:17px;
                font-style:normal;
                font-weight:$medium;
                line-height:1.35em;
                color:$white;
                
                &:before {
                    display:none;
                }
            }
            
            .btn {
                margin:3em 0 0;
            }
        }
    }
    
    &.ins {
        min-height:560px;
        
        .slick-list {
            margin:0;
        }
        
        .slick-slide {
            min-height:560px;
            padding:0;
            background:none;
        }
        
        .inner {
            position:relative;
            min-height:560px;
            background-color:$gray;
            background-repeat:no-repeat;
            background-position:center top;
            background-size:cover;
            z-index:-1;
        }
    
        .single-teammember &,
        .home & {
            margin-bottom:50px;
        }
        
        .overlay {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            z-index:-1;
            background: -moz-linear-gradient(left,  rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(left,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
            background: linear-gradient(to right,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
        }
        
        .inner_content {
            padding:40px 30% 76px 120px;
            box-sizing:border-box;
            z-index:1002;
        }
        
        p {
            margin:0 0 24px;
            font-size:0.75em;
            color:$white;
        }
        
        .eyebrow.label {
            position:relative;
            margin:0 0 84px;
            padding:0 0 0 50px;
            
            &:before {
                position:absolute;
                left:0;
                top:50%;
                width:31px;
                height:31px;
                transform:translateY(-50%);
                background:transparent url(../../images/insight-icon-article-white.svg) no-repeat left center;
                background-size:auto;
                content:'';
            }
            
            &.podcast {
                
                &:before {
                    background-image:url(../../images/insight-icon-podcast-white.svg);
                }
            }
            
            &.whitepaper {
                
                &:before {
                    background-image:url(../../images/insight-icon-whitepaper-white.svg);
                }
            }
            
            &.webinar,
            &.video {
                
                &:before {
                    background-image:url(../../images/insight-icon-video-white.svg);
                }
            }
        }
        
        a {
            color:$white !important;
            
            &:hover {
                opacity:0.7;
            }
        }
        
        h3 {
            margin:0 0 24px;
            font-size:42px;
            color:$white;
        }
        
        .meta {
            margin:0 0 24px;
            color:$white;
            font-size:0.75em;
            
            a {
                text-decoration:underline;
                font-weight:$bold;
            }
            
            &.top {
                margin:0 0 30px;
            }
        }
        .btn {
            margin:1.5em 0 0;
        }
    }
}

.metric_slider {
    position:relative;
    margin:0;
    min-height:560px;
    
    .home & {
        margin-top:20px;
        
        .slick-prev,
        .slick-next {
            background:transparent url(../../images/slider-control-white.svg) no-repeat center center;
        }
        
        .slick-prev {
            left: 50px;
            [dir="rtl"] & {
                left: auto;
                right: 50px;
            }
        }

        .slick-next {
            right: 50px;
            [dir="rtl"] & {
                left: 50px;
                right: auto;
            }
        }
    }
    
    * {
        box-sizing:border-box;
    }
    
    .slick-list {
        margin:0;
        padding:0 !important;
    }
    
    .slick-slide {
        min-height:560px;
        padding:0;
        background:none;
    }
    
    .inner {
        position:relative;
        min-height:560px;
        
        /*&:before {
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            background-color:transparent;
            background-repeat:no-repeat;
            background-position:center top;
            background-size:cover;
            content:'';
            filter: grayscale(100%);
        }*/
    }
    
    .bg_img {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-color:transparent;
        background-repeat:no-repeat;
        background-position:center top;
        background-size:cover;
        filter: grayscale(100%);
    }
    
    .overlay {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        @include background-opacity($blue, $opacity: 0.8);
    }
    
    .inner_content {
        position:relative;
        display:flex;
        align-items:flex-start;
        justify-content:flex-start;
        flex-direction:column;
        box-sizing:border-box;
        padding:8em calc(8.333% + 20px);
        
        .single-service & {
            padding:40px calc(8.333% + 20px) 60px;
        }
    }
    
    p {
        margin:0 0 0.5em;
        width:50%;
        font-weight:$semi;
        color:$white;
        
        &.eyebrow {
            width:100%;
            margin:0 0 2em;
        }
        
        .single-service & {
            width:60%;
        }
    }
    
    .btn {
        margin:2em 0 0;
    }
}

@media only screen and (max-width: #{$bp_small}) {
    
    .image_slider {
        
        
        .slick-slide {
            min-height:300px;
        }
        
        &.cs {
        
            &.testimonial {
            
                .inner {
                    min-height:300px;
                }
            
                .inner_content {
                    padding:46px 40px 62px;
                }
            
                .quote {
                    font-size:20px;
                }
            
                .cite_hs {
                    flex:0 0 50px;
                    width:50px;
                    height:50px;
                }
            
                cite,
                .cite {
                    flex:1 0 calc(100% - 64px);
                    font-size:16px;
                }
            }
        }
        
        &.ins {
            min-height:400px;
        
            .slick-slide {
                min-height:400px;
            }
        
            .inner {
                position:relative;
                min-height:400px;
            }
        
            .inner_content {
                padding:40px 40px 60px;
            }
        
            .eyebrow.label {
                margin:0 0 60px;
            }
        }
    }
    
    .metric_slider {
        min-height:400px;
        
        .home & {
            
            .slick-prev,
            .slick-next {
                top:50%;
            }
            
            .slick-prev {
                left: 20px;
                [dir="rtl"] & {
                    right: 20px;
                }
            }

            .slick-next {
                right: 20px;
                [dir="rtl"] & {
                    left: 20px;
                }
            }
            
        }
    
        .slick-slide {
            min-height:400px;
        }
    
        .inner {
            min-height:400px;
        }
        
        .inner_content {
            padding:10em 60px 6em;
        
            .single-service & {
                padding:40px 60px 60px;
            }
        }
    
        p {
            width:100%;
        
            .single-service & {
                width:100%;
            }
        }
    }
    
}

@media only screen and (max-width: 480px) {
    
    
    
}

@media screen and (min-width: 768px) and (max-width: #{$bp_mid}) {
    
    .image_slider {
        
        &.cs {
        
            &.testimonial {
            
                .inner_content {
                    padding:46px;
                }
            
                .quote {
                    font-size:20px;
                }
            }
        }
    }
    
    .metric_slider {
        
        
        p {
            width:80%;
            font-size:20px;
        
            .single-service & {
                width:80%;
            }
        }
    }
    
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    
    .image_slider {
        
        &.cs {
        
            &.testimonial {
            
                .inner_content {
                    padding:46px;
                }
            
                .quote {
                    font-size:20px;
                }
            }
        }
    }
    
    .metric_slider {
        
        
        p {
            width:80%;
            font-size:20px;
        
            .single-service & {
                width:80%;
            }
        }
    }
    
}

@media screen and (min-width: 1024px) and (max-width: #{$bp_full}) {
    
    
    
}